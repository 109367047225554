import React, { useEffect, useState } from 'react';
import { assetMapper } from '../helpers/propertyMapper';
import load from 'load-asset';
import { Property } from '../types/property.type';
import { AssetContextType } from '../types/asset-context.type';

export const AssetContext = React.createContext<AssetContextType>({});

type Asset = {
  url: string;
  event?: 'canplaythrough';
};

type ServerAsset = {
  type: string;
  id: number;
  filename: string;
  path: string;
};
export const AssetContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [assets, setAssets] = useState<Property>();
  const [progress, setProgress] = useState({ percent: '0%', done: false });

  const setPropertyAssets = (propertyId: number) => {
    setAssets(assetMapper(propertyId));
  };

  useEffect(() => {
    async function loadPropertyAssets() {
      try {
        let assetsUrls: string[] = [];
        let assetsToLoad: Asset[] = [];
        setProgress({ percent: '0%', done: false });

        // Taxonomy assets
        const res = await fetch(`${process.env.REACT_APP_ASSET_SERVER}/list`, {
          method: 'GET',
        });
        const assetsFromServer = (await res.json()) as ServerAsset[];
        assetsFromServer.forEach((x) => {
          assetsUrls.push(`${process.env.REACT_APP_ASSET_SERVER}${x.path}`);
        });

        // Consultations assets
        if (assets?.introSrc) {
          assetsUrls.push(assets.introSrc);
        }
        assetsUrls.push(require('../assets/video/h2.mp4'));
        assetsUrls.push(require('../assets/video/h3.mp4'));
        assetsUrls.push(require('../assets/video/h4.mp4'));
        assetsUrls.push(require('../assets/video/shine.mp4'));
        if (assets?.outroScr) {
          assetsUrls.push(assets.outroScr);
        }
        assetsUrls.push(require('../assets/video/sweetheart-rock.mp4'));
        assetsUrls.push(require('../assets/calendar/move.png'));
        assetsUrls.push(require('../assets/calendar/nourish.png'));
        assetsUrls.push(require('../assets/calendar/rest.png'));

        assetsToLoad = assetsUrls.map((x) => {
          if (x.includes('mp4') || x.includes('webm')) {
            return { url: x, event: 'canplaythrough' };
          } else {
            return { url: x };
          }
        });

        const results = await load.all(assetsToLoad, (data: any) => {
          console.log('Loading:', Math.round(data.progress * 100) + '%');
          setProgress({
            percent: Math.round(data.progress * 100) + '%',
            done: false,
          });
          if (data.error) console.error(data.error);
        });

        setProgress({
          percent: '100%',
          done: true,
        });
        console.log(
          'Following did not load: ',
          results.filter((x: any) => !x)
        );
        console.log('Done for now :)');
      } catch (e) {
        console.error(e);
      }
    }

    if (assets) {
      loadPropertyAssets();
    }
  }, [assets]);

  return (
    <AssetContext.Provider
      value={{
        assets,
        progress,
        setPropertyAssets,
      }}
    >
      {children}
    </AssetContext.Provider>
  );
};
