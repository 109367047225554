import React, { useContext } from 'react';
import styled from 'styled-components';
import { ScreenResolution } from '../../types/resolution.type';
import { rescale } from '../ratio.util';
import ResolutionContext from '../../contexts/ResolutionContext';

type ModalContainerProps = {
  resolution: ScreenResolution;
};
const ModalContainer = styled.div(({ resolution }: ModalContainerProps) => {
  return `
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: ${rescale(88, resolution, 'height')}px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 40;
  transition: 0.4s ease all;
`;
});

const ModalContentContainer = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  right: 0;
  width: 25%;
  transform: translateY(-50%);
  text-align: center;
`;

type Props = {
  children: React.ReactChild;
};

function Modal(props: Props) {
  const resolution = useContext(ResolutionContext);
  return (
    <ModalContainer resolution={resolution}>
      <ModalContentContainer>{props.children}</ModalContentContainer>
    </ModalContainer>
  );
}

export default Modal;
