import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import App from './App';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn:
    process.env.REACT_SENTRY_ENV === 'local'
      ? ''
      : 'https://f62595379482419b9ed46225bc8bfed4@sentry.io/1553382',
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
