import styled from 'styled-components';
import posed from 'react-pose';
import { ScreenResolution } from '../../types/resolution.type';
import { rescale } from '../ratio.util';

type DayBadgeContainerProps = {
  resolution: ScreenResolution;
  theme: {
    DayBadge: string;
  };
};
const DayBadgeContainer = styled.div(
  ({ resolution, theme }: DayBadgeContainerProps) => {
    return `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: ${rescale(400, resolution, 'width')}px;
  height: ${rescale(400, resolution, 'width')}px;
  border-radius: ${rescale(280, resolution, 'width')}px;
  background-color: ${theme.DayBadge};
  transition: 0.4s ease background-color;
  transition-delay: 0.1s;
`;
  }
);

const transition = {
  type: 'spring',
  damping: 13,
};
export const DayBadge = posed(DayBadgeContainer)({
  visible: {
    x: 0,
    opacity: 1,
    scale: 1,
    transition,
  },
  before: {
    opacity: 0,
    x: ({ resolution }: { resolution: ScreenResolution }) => {
      return rescale(-100, resolution, 'width');
    },
    scale: 1,
    transition,
  },
  after: {
    opacity: 0,
    x: ({ resolution }: { resolution: ScreenResolution }) => {
      return rescale(100, resolution, 'width');
    },
    scale: 1,
    transition,
  },
  props: { i: 0 },
});

DayBadgeContainer.defaultProps = {
  theme: {
    DayBadge: '#fff',
  },
};

type DayBadgeNameProps = {
  resolution: ScreenResolution;
  theme: {
    DayBadgeName: string;
  };
};
export const DayBadgeName = styled.div(
  ({ resolution, theme }: DayBadgeNameProps) => {
    return `
  margin-bottom: ${rescale(16, resolution, 'height')}px;
  color: ${theme.DayBadgeName};
  font-size: ${rescale(60, resolution, 'height')}px;
  transition: 0.4s ease color;
  transition-delay: 0.1s;
`;
  }
);

DayBadgeName.defaultProps = {
  theme: {
    DayBadgeName: '#686868',
  },
};

type DayBadgeNumberProps = {
  resolution: ScreenResolution;
  theme: {
    DayBadgeNumber: string;
  };
};
export const DayBadgeNumber = styled.div(
  ({ resolution, theme }: DayBadgeNumberProps) => {
    return `
  color: ${theme.DayBadgeNumber};
  font-size: ${rescale(140, resolution, 'height')}px;
  transition: 0.4s ease color;
  transition-delay: 0.2s;
`;
  }
);

DayBadgeNumber.defaultProps = {
  theme: {
    DayBadgeNumber: '#343434',
  },
};
