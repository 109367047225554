import React, { useContext } from 'react';
import styled from 'styled-components';
import posed from 'react-pose';

import Text from '../Text';
import { List, ListItem, ListSeparator, ListTitle } from '../List';
import {
  SmallGateContainer,
  SmallGateImage,
  SmallGateItem,
  SmallGateTitle,
} from '../Gate';
import SingleImage from '../SingleImage';
import { BottomAlign, TopAlign } from '../Sidebar';

import WallContext from '../../contexts/WallContext';
import { IntentionList } from '../IntentionList/IntentionList';
import { AssetContext } from '../../contexts/AssetContext';
import { CurrentPose } from 'react-pose/lib/components/PoseElement/types';
import { PropertyMapLegend } from '../../types/proeperty-map-legend.type';
import ResolutionContext from '../../contexts/ResolutionContext';
import { rescale } from '../ratio.util';
import { ScreenResolution } from '../../types/resolution.type';

type AvatarContainerProps = {
  resolution: ScreenResolution;
};
const AvatarContainer = styled.div(({ resolution }: AvatarContainerProps) => {
  return resolution.is16x9
    ? `
  width: ${rescale(236, resolution, 'width')}px;
  height: ${rescale(236, resolution, 'height')}px;
  border-radius: ${rescale(400, resolution, 'width')}px;
  background: #fff;
`
    : `
width: ${rescale(400, resolution, 'width')}px;
height: ${rescale(400, resolution, 'height')}px;
border-radius: ${rescale(400, resolution, 'width')}px;
background: #fff;
`;
});

const transition = {
  type: 'spring',
  damping: 13,
};
const Avatar = posed(AvatarContainer)({
  visible: {
    opacity: 1,
    x: 0,
    scale: 1,
    transition,
  },
  before: { opacity: 0, x: -100, scale: 1, transition },
  after: { opacity: 0, x: 100, scale: 1, transition },
});

function Intro({ pose }: { pose: CurrentPose | undefined }) {
  const data = useContext(WallContext);
  const resolution = useContext(ResolutionContext);
  return (
    <TopAlign pose={pose} resolution={resolution}>
      <Avatar
        style={{
          marginBottom: rescale(48, resolution, 'height'),
          backgroundColor: 'transparent',
        }}
        resolution={resolution}
      />
      <Text
        size="h2"
        style={{ marginBottom: rescale(48, resolution, 'height') }}
        resolution={resolution}
      >
        <strong>My Stay</strong>
      </Text>
      <List resolution={resolution}>
        <ListItem resolution={resolution}>
          <Text
            size="p"
            style={{ color: 'rgba(255, 255, 255, 0.6)' }}
            resolution={resolution}
          >
            Guide
          </Text>
          <Text size="p" style={{ color: '#fff' }} resolution={resolution}>
            {data.guideName}
          </Text>
        </ListItem>
        <ListItem resolution={resolution}>
          <Text
            size="p"
            style={{ color: 'rgba(255, 255, 255, 0.6)' }}
            resolution={resolution}
          >
            Guest
          </Text>
          <Text size="p" style={{ color: '#fff' }} resolution={resolution}>
            {data.guestName}
          </Text>
        </ListItem>
        <ListItem resolution={resolution}>
          <Text
            size="p"
            style={{ color: 'rgba(255, 255, 255, 0.6)' }}
            resolution={resolution}
          >
            Stay
          </Text>
          <Text size="p" style={{ color: '#fff' }} resolution={resolution}>
            {data.stayStart} – {data.stayEnd}
          </Text>
        </ListItem>
        <ListSeparator
          resolution={resolution}
          style={{
            visibility: resolution.is16x9 ? 'hidden' : 'visible',
          }}
        />
        <ListItem resolution={resolution}>
          <Text
            size="p"
            style={{ color: 'rgba(255, 255, 255, 0.6)' }}
            resolution={resolution}
          >
            00
          </Text>
          <Text size="p" style={{ color: '#fff' }} resolution={resolution}>
            Intentions
          </Text>
        </ListItem>
        <ListItem resolution={resolution}>
          <Text
            size="p"
            style={{ color: 'rgba(255, 255, 255, 0.6)' }}
            resolution={resolution}
          >
            01
          </Text>
          <Text size="p" style={{ color: '#fff' }} resolution={resolution}>
            Overview
          </Text>
        </ListItem>
        <ListItem resolution={resolution}>
          <Text
            size="p"
            style={{ color: 'rgba(255, 255, 255, 0.6)' }}
            resolution={resolution}
          >
            02
          </Text>
          <Text size="p" style={{ color: '#fff' }} resolution={resolution}>
            Health Metrics
          </Text>
        </ListItem>
        <ListItem resolution={resolution}>
          <Text
            size="p"
            style={{ color: 'rgba(255, 255, 255, 0.6)' }}
            resolution={resolution}
          >
            03
          </Text>
          <Text size="p" style={{ color: '#fff' }} resolution={resolution}>
            My Stay
          </Text>
        </ListItem>
      </List>
    </TopAlign>
  );
}

function WhatIsSensei({ pose }: { pose: CurrentPose | undefined }) {
  const resolution = useContext(ResolutionContext);
  return (
    <>
      <TopAlign pose={pose} resolution={resolution}>
        <Text
          size="h1"
          style={{
            marginTop: rescale(200, resolution, 'height'),
            marginBottom: rescale(150, resolution, 'height'),
            fontWeight: 400,
          }}
          resolution={resolution}
        >
          The Sensei Way
        </Text>
        <Text size="p" resolution={resolution}>
          We focus on three paths that help you get familiar and maintain a
          conversation with your body and mind{' '}
        </Text>
      </TopAlign>
      <SmallGateContainer
        pose={pose}
        width={resolution.width}
        height={resolution.height}
      >
        <SmallGateItem>
          <SmallGateTitle
            style={{ marginBottom: rescale(200, resolution, 'height') }}
            resolution={resolution}
          >
            Move
          </SmallGateTitle>
          <SmallGateImage
            width={resolution.width}
            src={require('../../assets/move.jpg')}
          />
        </SmallGateItem>
        <SmallGateItem>
          <SmallGateTitle
            style={{ marginBottom: rescale(200, resolution, 'height') }}
            resolution={resolution}
          >
            Nourish
          </SmallGateTitle>
          <SmallGateImage
            width={resolution.width}
            src={require('../../assets/nourish.jpg')}
          />
        </SmallGateItem>
        <SmallGateItem>
          <SmallGateTitle
            style={{ marginBottom: rescale(200, resolution, 'height') }}
            resolution={resolution}
          >
            Rest
          </SmallGateTitle>
          <SmallGateImage
            width={resolution.width}
            src={require('../../assets/rest.jpg')}
            style={{
              backgroundSize: 'cover',
            }}
          />
        </SmallGateItem>
      </SmallGateContainer>
    </>
  );
}

function WhatIsGuide({ pose }: { pose: CurrentPose | undefined }) {
  const data = useContext(WallContext);
  const resolution = useContext(ResolutionContext);
  const { assets } = useContext(AssetContext);

  return (
    <>
      <TopAlign pose={pose} resolution={resolution}>
        <Text
          resolution={resolution}
          size="h1"
          style={{
            marginTop: rescale(200, resolution, 'height'),
            marginBottom: rescale(48, resolution, 'height'),
            fontWeight: 400,
          }}
        >
          Sensei Guides
        </Text>
        <List resolution={resolution}>
          <ListItem resolution={resolution}>
            <Text
              size="p"
              style={{ color: 'rgba(255, 255, 255, 0.6)' }}
              resolution={resolution}
            >
              Guide
            </Text>
            <Text size="p" style={{ color: '#fff' }} resolution={resolution}>
              {data.guideName}
            </Text>
          </ListItem>
          <ListItem resolution={resolution}>
            <Text
              size="p"
              style={{ color: 'rgba(255, 255, 255, 0.6)' }}
              resolution={resolution}
            >
              Contact
            </Text>
            <Text
              size="p"
              style={{ color: '#fff', maxWidth: '70%' }}
              resolution={resolution}
            >
              <span>
                Feel free to contact{' '}
                <span style={{ whiteSpace: 'nowrap' }}>guides@sensei.com</span>{' '}
                for all wellness questions.
                <br />
                {resolution.is16x9 && (
                  <>
                    <br />
                  </>
                )}
                Contact {assets?.experienceEmail} for itinerary support.
                <br />
                <br />
              </span>
              Your Guide will be in constant communication with the Experience
              Specialist team.
            </Text>
          </ListItem>
        </List>
      </TopAlign>
      <SingleImage
        id={'SingleImage'}
        resolution={resolution}
        pose={pose}
        src={require('../../assets/guides.jpg')}
        style={{
          backgroundSize:
            resolution.name === '8k'
              ? resolution.width - resolution.width * 0.2
              : resolution.width,
        }}
      />
    </>
  );
}

function AssessmentsMerged({ pose }: { pose: CurrentPose | undefined }) {
  const resolution = useContext(ResolutionContext);
  return (
    <BottomAlign pose={pose} resolution={resolution}>
      <Text
        size="h1"
        style={{
          marginTop: rescale(200, resolution, 'height'),
          marginBottom: rescale(48, resolution, 'height'),
        }}
        resolution={resolution}
      >
        <strong>Health Metrics</strong>
        <br />
        The Essentials
      </Text>
      <List resolution={resolution}>
        <ListItem resolution={resolution}>
          <Text
            size="p"
            style={{ color: 'rgba(255, 255, 255, 0.6)' }}
            resolution={resolution}
          >
            00
          </Text>
          <Text size="p" style={{ color: '#fff' }} resolution={resolution}>
            Blood Pressure
          </Text>
        </ListItem>
        <ListItem resolution={resolution}>
          <Text
            size="p"
            style={{ color: 'rgba(255, 255, 255, 0.6)' }}
            resolution={resolution}
          >
            01
          </Text>
          <Text
            size="p"
            style={{ color: '#fff', maxWidth: '70%' }}
            resolution={resolution}
          >
            Body Composition
          </Text>
        </ListItem>
        <ListItem resolution={resolution}>
          <Text
            size="p"
            style={{ color: 'rgba(255, 255, 255, 0.6)' }}
            resolution={resolution}
          >
            02
          </Text>
          <Text
            size="p"
            style={{ color: '#fff', maxWidth: '70%' }}
            resolution={resolution}
          >
            Functional Movement Screen
          </Text>
        </ListItem>
      </List>
    </BottomAlign>
  );
}

function BloodPressure({ pose }: { pose: CurrentPose | undefined }) {
  const resolution = useContext(ResolutionContext);
  return (
    <BottomAlign pose={pose} resolution={resolution}>
      <Text
        size="h1"
        style={{
          marginTop: rescale(200, resolution, 'height'),
          marginBottom: rescale(48, resolution, 'height'),
        }}
        resolution={resolution}
      >
        <strong>Assessments</strong>
        <br />
        Blood Pressure
      </Text>
      <Text size="p" resolution={resolution}>
        Blood pressure monitoring is essential to preventing heart disease and
        strokes.{' '}
      </Text>
    </BottomAlign>
  );
}

function BodyComposition({ pose }: { pose: CurrentPose | undefined }) {
  const resolution = useContext(ResolutionContext);
  return (
    <BottomAlign pose={pose} resolution={resolution}>
      <Text
        size="h1"
        style={{
          marginTop: rescale(200, resolution, 'height'),
          marginBottom: rescale(48, resolution, 'height'),
        }}
        resolution={resolution}
      >
        <strong>Assessments</strong>
        <br />
        Body Composition
      </Text>
      <Text size="p" resolution={resolution}>
        Breaks down weight into body compartments, namely, Fat Mass, Fat-free
        Mass, Body Water and Skeletal Muscle Mass.{' '}
      </Text>
    </BottomAlign>
  );
}

function BodyScanner({ pose }: { pose: CurrentPose | undefined }) {
  const resolution = useContext(ResolutionContext);
  return (
    <BottomAlign pose={pose} resolution={resolution}>
      <Text
        size="h1"
        style={{
          marginTop: rescale(200, resolution, 'height'),
          marginBottom: rescale(48, resolution, 'height'),
        }}
        resolution={resolution}
      >
        <strong>Assessments</strong>
        <br />
        Functional Movement Screen
      </Text>
      <Text size="p" resolution={resolution}>
        The FMS is a tool used to identify asymmetries which result in
        functional movement deficiencies.{' '}
      </Text>
    </BottomAlign>
  );
}

function VR({ pose }: { pose: CurrentPose | undefined }) {
  const resolution = useContext(ResolutionContext);
  return (
    <BottomAlign pose={pose} resolution={resolution}>
      <Text
        style={{
          marginTop: rescale(200, resolution, 'height'),
          marginBottom: rescale(48, resolution, 'height'),
        }}
        resolution={resolution}
      >
        <strong>Assessments</strong>
        <br />
        Your Wellbeing
      </Text>
      <Text size="p" resolution={resolution}>
        Let’s take time to discuss your daily rhythms.{' '}
      </Text>
    </BottomAlign>
  );
}

function Meditation({ pose }: { pose: CurrentPose | undefined }) {
  const resolution = useContext(ResolutionContext);
  return (
    <BottomAlign pose={pose} resolution={resolution}>
      <Text
        size="h1"
        style={{
          marginTop: rescale(200, resolution, 'height'),
          marginBottom: rescale(48, resolution, 'height'),
        }}
        resolution={resolution}
      >
        <strong>
          Meditation
          <br />
          In Progress
        </strong>
      </Text>
      <Text size="p" resolution={resolution}>
        Insert timer here.
      </Text>
    </BottomAlign>
  );
}

function MyStay({ pose }: { pose: CurrentPose | undefined }) {
  const data = useContext(WallContext);
  const resolution = useContext(ResolutionContext);

  return (
    <>
      <TopAlign pose={pose} resolution={resolution}>
        <Text
          size="h1"
          style={{ marginTop: rescale(200, resolution, 'height') }}
          resolution={resolution}
        >
          <span style={{ fontWeight: 400 }}>My Stay</span>
        </Text>
        <Text
          style={{ marginBottom: rescale(48, resolution, 'height') }}
          resolution={resolution}
        >
          <span style={{ opacity: 0.4 }}>
            {data.stayStart} to {data.stayEnd}
          </span>
        </Text>
      </TopAlign>
    </>
  );
}

function Movement({ pose }: { pose: CurrentPose | undefined }) {
  const resolution = useContext(ResolutionContext);
  return (
    <>
      <TopAlign pose={pose} resolution={resolution}>
        <Text resolution={resolution}>
          <img
            src={require('../../assets/calendar/move.png')}
            style={{
              width: rescale(2492, resolution, 'width'),
              height: rescale(1633, resolution, 'height'),
              top: rescale(-830, resolution, 'height'),
              left: rescale(-940, resolution, 'width'),
              position: 'absolute',
            }}
            alt=""
          />
        </Text>
        <Text
          size="h1"
          style={{
            marginTop: rescale(200, resolution, 'height'),
            marginBottom: rescale(48, resolution, 'height'),
          }}
          resolution={resolution}
        >
          <span style={{ fontWeight: 400 }}>Move</span>
        </Text>
        <Text size="p" resolution={resolution}>
          Consistent, dynamic movement is how we effectively interact with our
          environment.
        </Text>
      </TopAlign>
    </>
  );
}

function Nourishment({ pose }: { pose: CurrentPose | undefined }) {
  const data = useContext(WallContext);
  const hasMeals = data.meals && data.meals.length;
  const resolution = useContext(ResolutionContext);

  return (
    <>
      <TopAlign pose={pose} resolution={resolution}>
        <Text resolution={resolution}>
          <img
            src={require('../../assets/calendar/nourish.png')}
            style={{
              width: rescale(2492, resolution, 'width'),
              height: rescale(1633, resolution, 'height'),
              top: rescale(-830, resolution, 'height'),
              left: rescale(-940, resolution, 'width'),
              position: 'absolute',
            }}
            alt=""
          />
        </Text>
        <Text
          size="h1"
          style={{
            marginTop: rescale(200, resolution, 'height'),
            marginBottom: rescale(48, resolution, 'height'),
          }}
          resolution={resolution}
        >
          <span style={{ fontWeight: 400 }}>Nourish</span>
        </Text>
        <Text size="p" resolution={resolution}>
          What fuels us through nutrition and emotional connection.
        </Text>
        {hasMeals && (
          <Text size="p" resolution={resolution}>
            Your meal times: {data.meals?.join(', ')}
          </Text>
        )}
      </TopAlign>
    </>
  );
}

function Rest({ pose }: { pose: CurrentPose | undefined }) {
  const data = useContext(WallContext);
  const resolution = useContext(ResolutionContext);
  const hasWake = data.wake;

  return (
    <>
      <TopAlign pose={pose} resolution={resolution}>
        <Text resolution={resolution}>
          <img
            src={require('../../assets/calendar/rest.png')}
            style={{
              width: rescale(2492, resolution, 'width'),
              height: rescale(1633, resolution, 'height'),
              top: rescale(-830, resolution, 'height'),
              left: rescale(-940, resolution, 'height'),
              position: 'absolute',
            }}
            alt=""
          />
        </Text>
        <Text
          size="h1"
          style={{
            marginTop: rescale(200, resolution, 'height'),
            marginBottom: rescale(48, resolution, 'height'),
          }}
          resolution={resolution}
        >
          <span style={{ fontWeight: 400 }}>Rest</span>
        </Text>
        <Text size="p" resolution={resolution}>
          How we recover and grow.
        </Text>
        {hasWake && (
          <Text size="p" resolution={resolution}>
            Your wake time: {data.wake}
          </Text>
        )}
      </TopAlign>
    </>
  );
}

function MeditationComplete({ pose }: { pose: CurrentPose | undefined }) {
  const data = useContext(WallContext);
  const resolution = useContext(ResolutionContext);

  return (
    <TopAlign pose={pose} resolution={resolution}>
      <Avatar
        style={{
          marginBottom: rescale(48, resolution, 'height'),
          background: 'transparent',
        }}
        resolution={resolution}
      />
      <Text
        size="h2"
        style={{ marginBottom: rescale(48, resolution, 'height') }}
        resolution={resolution}
      >
        <strong>Meditation Complete</strong>
        <br />
        with {data.guideName}
      </Text>
      <List resolution={resolution}>
        <ListItem resolution={resolution}>
          <Text
            size="p"
            style={{ color: 'rgba(255, 255, 255, 0.6)' }}
            resolution={resolution}
          >
            Scene
          </Text>
          <Text size="p" style={{ color: '#fff' }} resolution={resolution}>
            Forest
          </Text>
        </ListItem>
        <ListItem resolution={resolution}>
          <Text
            size="p"
            style={{ color: 'rgba(255, 255, 255, 0.6)' }}
            resolution={resolution}
          >
            Start HR
          </Text>
          <Text size="p" style={{ color: '#fff' }} resolution={resolution}>
            120 bpm
          </Text>
        </ListItem>
        <ListItem resolution={resolution}>
          <Text
            size="p"
            style={{ color: 'rgba(255, 255, 255, 0.6)' }}
            resolution={resolution}
          >
            End HR
          </Text>
          <Text size="p" style={{ color: '#fff' }} resolution={resolution}>
            88 bpm
          </Text>
        </ListItem>
      </List>
    </TopAlign>
  );
}

function PAQ({ pose }: { pose: CurrentPose | undefined }) {
  const data = useContext(WallContext);
  const hasScheduleType = data.scheduleType;
  const hasIntentions = data.intentions && data.intentions.length;
  const resolution = useContext(ResolutionContext);
  const concatedIntentions = React.useMemo(() => {
    let intentions = '';
    // Depending on PAQ type Old vs Package Specific intentions can come in three ways
    // an array of strings,
    // a string of comma seperated values,
    // an array with a single value that is a string of comma seperated values
    if (
      data.intentions &&
      Array.isArray(data.intentions) &&
      data.intentions.length > 1
    ) {
      const intentionsArray: string[] = data.intentions;
      intentionsArray.forEach((intention, i) => {
        i < intentionsArray.length - 1
          ? (intentions += intention.toLowerCase() + ', ')
          : (intentions += intention.toLowerCase());
      });
    } else if (
      data.intentions &&
      Array.isArray(data.intentions) &&
      data.intentions.length === 1
    ) {
      const intentionsString = data.intentions[0];
      const split = intentionsString.split(',');
      split.forEach((splitIntention, i) => {
        splitIntention = splitIntention.toLowerCase();
        i >= split.length - 1
          ? (intentions += splitIntention)
          : (intentions += splitIntention + ', ');
      });
    } else if (
      data.intentions &&
      typeof data.intentions == 'string' &&
      data.intentions.length > 0
    ) {
      const split = data.intentions.split(',');
      split.forEach((splitIntention, i) => {
        splitIntention = splitIntention.toLowerCase();
        i >= split.length - 1
          ? (intentions += splitIntention)
          : (intentions += splitIntention + ', ');
      });
    }
    return intentions;
  }, [data.intentions]);

  return (
    <TopAlign pose={pose} resolution={resolution}>
      <Text
        size="h1"
        style={{ marginTop: rescale(200, resolution, 'height') }}
        resolution={resolution}
      >
        <span style={{ fontWeight: 400 }}>My Intentions</span>
      </Text>
      <Text
        style={{ marginBottom: rescale(48, resolution, 'height') }}
        resolution={resolution}
      >
        <span style={{ opacity: 0.4 }}>
          {data.stayStart} to {data.stayEnd}
        </span>
      </Text>
      {(hasIntentions || hasScheduleType) && (
        <List
          style={{ marginTop: rescale(214, resolution, 'height') }}
          resolution={resolution}
        >
          <ListTitle style={{ textTransform: 'none' }} resolution={resolution}>
            From your pre-arrival questionnaire:
          </ListTitle>
          {hasScheduleType && (
            <ListItem resolution={resolution}>
              <Text
                size="p"
                style={{ color: 'rgba(255, 255, 255, 0.6)' }}
                resolution={resolution}
              >
                Activity
              </Text>
              <Text
                size="p"
                style={{
                  color: '#fff',
                  textTransform: 'capitalize',
                  maxWidth: rescale(1300, resolution, 'width') + 'px',
                }}
                resolution={resolution}
              >
                {data.scheduleType}
              </Text>
            </ListItem>
          )}
          {hasIntentions && (
            <ListItem resolution={resolution}>
              <Text
                size="p"
                style={{ color: 'rgba(255, 255, 255, 0.6)' }}
                resolution={resolution}
              >
                {/* Intention */}
              </Text>
              <Text
                size="p"
                style={{
                  color: '#fff',
                  textTransform: 'capitalize',
                  marginLeft: '-' + rescale(40, resolution, 'width') + 'px',
                  //marginRight: '-500px',
                }}
                resolution={resolution}
              >
                <IntentionList pose={pose} intentions={concatedIntentions} />
              </Text>
            </ListItem>
          )}
        </List>
      )}
    </TopAlign>
  );
}

function StayMap({ pose }: { pose: CurrentPose | undefined }) {
  const { assets } = useContext(AssetContext);
  const resolution = useContext(ResolutionContext);
  return (
    <>
      <TopAlign pose={pose} resolution={resolution}>
        <Text
          size="h1"
          style={{ marginTop: rescale(116, resolution, 'height') }}
          resolution={resolution}
        >
          <span style={{ fontWeight: 400 }}>{assets?.map?.name}</span>
        </Text>

        <List
          style={{ marginTop: rescale(240, resolution, 'height') }}
          resolution={resolution}
        >
          {(assets?.map?.legend || []).map((item: PropertyMapLegend) => (
            <ListItem
              style={{ marginBottom: rescale(96, resolution, 'height') }}
              key={item.number}
              resolution={resolution}
            >
              <Text
                resolution={resolution}
                size="p"
                style={{
                  color: 'rgba(255, 255, 255, 0.6)',
                  fontSize: rescale(96, resolution, 'height'),
                }}
              >
                {item.number}
              </Text>
              <Text
                size="p"
                style={{
                  color: '#fff',
                  fontSize: rescale(96, resolution, 'height'),
                }}
                resolution={resolution}
              >
                {item.name}
              </Text>
            </ListItem>
          ))}
        </List>
      </TopAlign>
      <SingleImage
        resolution={resolution}
        pose={pose}
        src={assets?.map?.src}
        style={{
          backgroundPosition: 'bottom left',
        }}
      />
    </>
  );
}

export {
  Intro,
  WhatIsSensei,
  WhatIsGuide,
  BloodPressure,
  BodyComposition,
  BodyScanner,
  Movement,
  VR,
  Meditation,
  MyStay,
  Nourishment,
  Rest,
  PAQ,
  MeditationComplete,
  StayMap,
  AssessmentsMerged,
};
