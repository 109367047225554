import React from 'react';
import { ScreenResolution } from '../types/resolution.type';
import { SUPPORTED_RESOLUTIONS } from '../components/constants';

const ResolutionContext = React.createContext<ScreenResolution>({
  width: SUPPORTED_RESOLUTIONS[0].width,
  height: SUPPORTED_RESOLUTIONS[0].height,
  name: SUPPORTED_RESOLUTIONS[0].name,
  is16x9: false,
});

export default ResolutionContext;
