import { UpcomingAppointment } from '../types/upcoming-appointment.type';

export const demoSessionMock = (property: number): UpcomingAppointment =>
  property === 2
    ? {
      id: '1',
      startDate: '2020-01-01T17:00:00+00:00',
      endDate: '2020-01-01T18:00:00+00:00',
      location: {
        name: 'Sync One',
      },
      bookingParty: {
        id: '1',
        member: { user: { firstName: 'Janet', lastName: 'Doe' } },
        booking: {
          startDate: '2020-01-01',
          endDate: '2020-01-05',
          property: { id: property },
        },
        appointments: [
          {
            id: '666',
            startDate: '2019-12-31',
            endDate: '2019-12-31',
            activity: {
              id: 9,
              name: 'Arrival Day',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'ArrivalDay' },
            },
            location: null,
          },
          {
            id: '43',
            startDate: '2020-01-01T16:00:00+00:00',
            endDate: '2020-01-01T17:00:00+00:00',
            activity: {
              id: 9,
              name: 'Breakfast',
              description: null,
              isAssessment: true,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '63',
            startDate: '2020-01-01T19:00:00+00:00',
            endDate: '2020-01-01T20:00:00+00:00',
            activity: {
              id: 30,
              name: 'My Intention',
              description: null,
              isAssessment: true,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: { name: 'Sync 1' },
          },
          {
            id: '44',
            startDate: '2020-01-01T20:00:00+00:00',
            endDate: '2020-01-01T21:00:00+00:00',
            activity: {
              id: 26,
              name: 'Lunch',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '45',
            startDate: '2020-01-01T22:00:00+00:00',
            endDate: '2020-01-01T23:00:00+00:00',
            activity: {
              id: 27,
              name: 'Sensei Yoga Flow',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Move' },
            },
            location: null,
          },
          {
            id: '46',
            startDate: '2020-01-02T00:00:00+00:00',
            endDate: '2020-01-02T01:00:00+00:00',
            activity: {
              id: 28,
              name: 'Thermal Body Mapping and Massage',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Nourish' },
            },
            location: null,
          },
          {
            id: '47',
            startDate: '2020-01-02T02:00:00+00:00',
            endDate: '2020-01-02T03:00:00+00:00',
            activity: {
              id: 29,
              name: 'Dinner at Sushi Bar',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '144',
            startDate: '2020-01-02T15:30:00+00:00',
            endDate: '2020-01-02T16:30:00+00:00',
            activity: {
              id: 30,
              name: 'Fitness with Biomarkers 1:1',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Move' },
            },
            location: null,
          },
          {
            id: '148',
            startDate: '2020-01-02T17:00:00+00:00',
            endDate: '2020-01-02T18:00:00+00:00',
            activity: {
              id: 31,
              name: 'Breakfast',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '45',
            startDate: '2020-01-02T18:30:00+00:00',
            endDate: '2020-01-02T19:30:00+00:00',
            activity: {
              id: 45,
              name: 'Getting Started with Meditation',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Rest' },
            },
            location: null,
          },
          {
            id: '46',
            startDate: '2020-01-02T20:00:00+00:00',
            endDate: '2020-01-02T21:00:00+00:00',
            activity: {
              id: 9,
              name: 'Lunch',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '53',
            startDate: '2020-01-02T21:30:00+00:00',
            endDate: '2020-01-02T22:30:00+00:00',
            activity: {
              id: 58,
              name: 'Desert Poultice Nourish Spa Treatment',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Nourish' },
            },
            location: { name: 'Exercise Pavillion' },
          },
          {
            id: '56',
            startDate: '2020-01-03T00:00:00+00:00',
            endDate: '2020-01-03T02:00:00+00:00',
            activity: {
              id: 58,
              name: 'Sound Journey',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Nourish' },
            },
            location: { name: '' },
          },
          {
            id: '56',
            startDate: '2020-01-03T02:00:00+00:00',
            endDate: '2020-01-03T03:00:00+00:00',
            activity: {
              id: 58,
              name: 'Dinner at Sensei by Nobu',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: { name: '' },
          },
          {
            id: '47',
            startDate: '2020-01-03T16:00:00+00:00',
            endDate: '2020-01-03T17:00:00+00:00',
            activity: {
              id: 45,
              name: 'Breakfast',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '256',
            startDate: '2020-01-03T19:00:00+00:00',
            endDate: '2020-01-03T20:00:00+00:00',
            activity: {
              id: 58,
              name: 'Wall Yoga',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Move' },
            },
            location: { name: '' },
          },
          {
            id: '48',
            startDate: '2020-01-03T20:30:00+00:00',
            endDate: '2020-01-03T21:30:00+00:00',
            activity: {
              id: 9,
              name: 'Lunch',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '55',
            startDate: '2020-01-03T22:00:00+00:00',
            endDate: '2020-01-03T23:00:00+00:00',
            activity: {
              id: 55,
              name: 'Mindset with Biomarkers 1:1',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Rest' },
            },
            location: { name: 'Hale 1' },
          },
          {
            id: '49',
            startDate: '2020-01-04T00:00:00+00:00',
            endDate: '2020-01-04T01:00:00+00:00',
            activity: {
              id: 26,
              name: 'Gua Sha Facial',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Nourish' },
            },
            location: null,
          },
          {
            id: '5115',
            startDate: '2020-01-04T02:00:00+00:00',
            endDate: '2020-01-04T03:00:00+00:00',
            activity: {
              id: 55,
              name: 'Dinner at Sensei by Nobu',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: { name: '' },
          },
          {
            id: '250',
            startDate: '2020-01-04T15:30:00+00:00',
            endDate: '2020-01-04T16:00:00+00:00',
            activity: {
              id: 45,
              name: 'Nutrition Biomarker Draw',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Nourish' },
            },
            location: null,
          },
          {
            id: '50',
            startDate: '2020-01-04T16:00:00+00:00',
            endDate: '2020-01-04T17:00:00+00:00',
            activity: {
              id: 45,
              name: 'Breakfast',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '55',
            startDate: '2020-01-04T18:00:00+00:00',
            endDate: '2020-01-04T19:00:00+00:00',
            activity: {
              id: 9,
              name: 'Nutrition with Biomarkers 1:1',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Nourish' },
            },
            location: null,
          },
          {
            id: '5961',
            startDate: '2020-01-04T20:00:00+00:00',
            endDate: '2020-01-04T21:00:00+00:00',
            activity: {
              id: 55,
              name: 'Lunch',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: { name: '' },
          },
          {
            id: '56',
            startDate: '2020-01-04T22:00:00+00:00',
            endDate: '2020-01-04T23:00:00+00:00',
            activity: {
              id: 55,
              name: 'Private Golf Lesson with Professional',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Move' },
            },
            location: { name: 'Hale 1' },
          },
          {
            id: '57',
            startDate: '2020-01-05T00:00:00+00:00',
            endDate: '2020-01-05T01:00:00+00:00',
            activity: {
              id: 26,
              name: 'Functional Fascia',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Move' },
            },
            location: { name: '' },
          },
          {
            id: '87656',
            startDate: '2020-01-05T02:00:00+00:00',
            endDate: '2020-01-05T03:00:00+00:00',
            activity: {
              id: 55,
              name: 'Dinner at Sushi Bar',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: { name: '' },
          },
          {
            id: '58',
            startDate: '2020-01-05T16:00:00+00:00',
            endDate: '2020-01-05T17:00:00+00:00',
            activity: {
              id: 45,
              name: 'Breakfast',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '59',
            startDate: '2020-01-05T17:30:00+00:00',
            endDate: '2020-01-05T18:30:00+00:00',
            activity: {
              id: 9,
              name: 'Desert Ridge Hike',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Move' },
            },
            location: null,
          },
          {
            id: '60',
            startDate: '2020-01-05T20:00:00+00:00',
            endDate: '2020-01-05T21:00:00+00:00',
            activity: {
              id: 55,
              name: 'Lunch',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: { name: '' },
          },
          {
            id: '61',
            startDate: '2020-01-05T22:00:00+00:00',
            endDate: '2020-01-05T24:00:00+00:00',
            activity: {
              id: 26,
              name: 'My Plan',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
        ],
        guideForBooking: {
          user: {
            id: '12',
            firstName: 'Kelly',
            lastName: 'Georgiou',
            cellPhone: '8084521594',
          },
        },
        prearrival: {
          preferredWakeTime: '6am',
          mealTimes: ['no_breakfast', '12pm', '6pm'],
          intentions: [
            'IMMERSIVE_WELLBEING,CONNECT_WITH_NATURE_ART,DISCOVER_HEALTHY_PRACTICES',
          ],
          scheduleType: 'active',
        },
      },
    }
    : {
      id: '2',
      startDate: '2020-01-01T17:00:00+00:00',
      endDate: '2020-01-01T18:00:00+00:00',
      location: {
        name: 'Test2',
      },
      bookingParty: {
        id: '2',
        member: { user: { firstName: 'Jane', lastName: 'Doe' } },
        booking: {
          startDate: '2020-01-01',
          endDate: '2020-01-15',
          property: { id: property },
        },
        appointments: [
          {
            id: '43',
            startDate: '2020-01-01T17:00:00+00:00',
            endDate: '2020-01-01T18:00:00+00:00',
            activity: {
              id: 9,
              name: 'Breakfast',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '63',
            startDate: '2020-01-01T19:00:00+00:00',
            endDate: '2020-01-01T20:00:00+00:00',
            activity: {
              id: 30,
              name: 'G1 Session',
              description: null,
              isAssessment: true,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: { name: 'Sync 1' },
          },
          {
            id: '44',
            startDate: '2020-01-01T22:00:00+00:00',
            endDate: '2020-01-01T23:00:00+00:00',
            activity: {
              id: 26,
              name: 'Lunch',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '144',
            startDate: '2020-01-02T01:00:00+00:00',
            endDate: '2020-01-02T02:00:00+00:00',
            activity: {
              id: 26,
              name: 'Classic Massage',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Rest' },
            },
            location: null,
          },
          {
            id: '148',
            startDate: '2020-01-02T03:00:00+00:00',
            endDate: '2020-01-02T04:00:00+00:00',
            activity: {
              id: 26,
              name: 'Manicure 60',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Rest' },
            },
            location: null,
          },
          {
            id: '45',
            startDate: '2020-01-02T05:00:00+00:00',
            endDate: '2020-01-02T06:30:00+00:00',
            activity: {
              id: 45,
              name: 'Dinner',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '46',
            startDate: '2020-01-02T17:00:00+00:00',
            endDate: '2020-01-02T18:00:00+00:00',
            activity: {
              id: 9,
              name: 'Breakfast',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '53',
            startDate: '2020-01-02T19:00:00+00:00',
            endDate: '2020-01-02T20:00:00+00:00',
            activity: {
              id: 58,
              name: 'Minimalist Resistance Training',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Move' },
            },
            location: { name: 'Exercise Pavillion' },
          },
          {
            id: '56',
            startDate: '2020-01-02T21:00:00+00:00',
            endDate: '2020-01-02T22:00:00+00:00',
            activity: {
              id: 58,
              name: 'Nutrition 1:1',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Nourish' },
            },
            location: { name: '' },
          },
          {
            id: '56',
            startDate: '2020-01-03T01:00:00+00:00',
            endDate: '2020-01-03T02:00:00+00:00',
            activity: {
              id: 58,
              name: 'Aqua Therapy 60',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: { name: '' },
          },
          {
            id: '47',
            startDate: '2020-01-03T05:00:00+00:00',
            endDate: '2020-01-03T06:30:00+00:00',
            activity: {
              id: 45,
              name: 'Dinner',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '256',
            startDate: '2020-01-03T07:00:00+00:00',
            endDate: '2020-01-03T09:00:00+00:00',
            activity: {
              id: 58,
              name: 'Sunset cruise',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Move' },
            },
            location: { name: '' },
          },
          {
            id: '48',
            startDate: '2020-01-03T17:00:00+00:00',
            endDate: '2020-01-03T18:00:00+00:00',
            activity: {
              id: 9,
              name: 'Breakfast',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '55',
            startDate: '2020-01-03T19:00:00+00:00',
            endDate: '2020-01-03T20:00:00+00:00',
            activity: {
              id: 55,
              name: 'Sensei Yoga Flow',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Move' },
            },
            location: { name: 'Hale 1' },
          },
          {
            id: '49',
            startDate: '2020-01-03T22:00:00+00:00',
            endDate: '2020-01-03T23:00:00+00:00',
            activity: {
              id: 26,
              name: 'Lunch',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '5115',
            startDate: '2020-01-04T00:00:00+00:00',
            endDate: '2020-01-04T01:00:00+00:00',
            activity: {
              id: 55,
              name: 'Finding Peace Walking Meditation',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Nourish' },
            },
            location: { name: '' },
          },
          {
            id: '250',
            startDate: '2020-01-04T03:00:00+00:00',
            endDate: '2020-01-04T04:00:00+00:00',
            activity: {
              id: 45,
              name: 'Loop Hike',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Rest' },
            },
            location: null,
          },
          {
            id: '50',
            startDate: '2020-01-04T05:00:00+00:00',
            endDate: '2020-01-04T06:30:00+00:00',
            activity: {
              id: 45,
              name: 'Dinner',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '55',
            startDate: '2020-01-04T17:00:00+00:00',
            endDate: '2020-01-04T18:00:00+00:00',
            activity: {
              id: 9,
              name: 'Breakfast',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '5961',
            startDate: '2020-01-04T19:00:00+00:00',
            endDate: '2020-01-04T20:00:00+00:00',
            activity: {
              id: 55,
              name: 'Ridge Hike',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Move' },
            },
            location: { name: '' },
          },
          {
            id: '56',
            startDate: '2020-01-04T21:00:00+00:00',
            endDate: '2020-01-04T21:00:00+00:00',
            activity: {
              id: 55,
              name: 'Lomi Lomi 60',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: { name: 'Hale 1' },
          },
          {
            id: '57',
            startDate: '2020-01-04T22:00:00+00:00',
            endDate: '2020-01-04T23:00:00+00:00',
            activity: {
              id: 26,
              name: 'Lunch',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '87656',
            startDate: '2020-01-05T02:00:00+00:00',
            endDate: '2020-01-05T04:00:00+00:00',
            activity: {
              id: 55,
              name: 'UTV Tour',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Nourish' },
            },
            location: { name: '' },
          },
          {
            id: '58',
            startDate: '2020-01-05T05:00:00+00:00',
            endDate: '2020-01-05T06:30:00+00:00',
            activity: {
              id: 45,
              name: 'Dinner',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '59',
            startDate: '2020-01-05T17:00:00+00:00',
            endDate: '2020-01-05T18:00:00+00:00',
            activity: {
              id: 9,
              name: 'Breakfast',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '60',
            startDate: '2020-01-05T19:00:00+00:00',
            endDate: '2020-01-05T20:00:00+00:00',
            activity: {
              id: 55,
              name: 'Restorative Yoga',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Rest' },
            },
            location: { name: '' },
          },
          {
            id: '61',
            startDate: '2020-01-05T22:00:00+00:00',
            endDate: '2020-01-05T23:00:00+00:00',
            activity: {
              id: 26,
              name: 'Lunch',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '62',
            startDate: '2020-01-06T02:00:00+00:00',
            endDate: '2020-01-06T03:00:00+00:00',
            activity: {
              id: 45,
              name: 'Vinyasa and Sound',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Rest' },
            },
            location: null,
          },
          {
            id: '62',
            startDate: '2020-01-06T05:00:00+00:00',
            endDate: '2020-01-06T06:30:00+00:00',
            activity: {
              id: 45,
              name: 'Dinner',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '63',
            startDate: '2020-01-06T17:00:00+00:00',
            endDate: '2020-01-06T18:00:00+00:00',
            activity: {
              id: 9,
              name: 'Breakfast',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '64',
            startDate: '2020-01-06T19:00:00+00:00',
            endDate: '2020-01-06T20:00:00+00:00',
            activity: {
              id: 55,
              name: 'Lomi Lomi 60',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: { name: 'Hale 1' },
          },
          {
            id: '65',
            startDate: '2020-01-06T22:00:00+00:00',
            endDate: '2020-01-06T23:00:00+00:00',
            activity: {
              id: 26,
              name: 'Lunch',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '66',
            startDate: '2020-01-07T05:00:00+00:00',
            endDate: '2020-01-07T06:30:00+00:00',
            activity: {
              id: 45,
              name: 'Dinner',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '67',
            startDate: '2020-01-07T17:00:00+00:00',
            endDate: '2020-01-07T18:00:00+00:00',
            activity: {
              id: 9,
              name: 'Breakfast',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '68',
            startDate: '2020-01-07T19:00:00+00:00',
            endDate: '2020-01-07T20:00:00+00:00',
            activity: {
              id: 55,
              name: 'Strength Circuit',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Move' },
            },
            location: { name: 'Hale 1' },
          },
          {
            id: '69',
            startDate: '2020-01-07T22:00:00+00:00',
            endDate: '2020-01-07T23:00:00+00:00',
            activity: {
              id: 26,
              name: 'Lunch',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '88169',
            startDate: '2020-01-08T01:00:00+00:00',
            endDate: '2020-01-08T02:00:00+00:00',
            activity: {
              id: 26,
              name: 'Volunteer Activity ',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Nourish' },
            },
            location: null,
          },
          {
            id: '70',
            startDate: '2020-01-08T05:00:00+00:00',
            endDate: '2020-01-08T06:30:00+00:00',
            activity: {
              id: 45,
              name: 'Dinner',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '71',
            startDate: '2020-01-08T17:00:00+00:00',
            endDate: '2020-01-08T18:00:00+00:00',
            activity: {
              id: 9,
              name: 'Breakfast',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '72',
            startDate: '2020-01-08T19:00:00+00:00',
            endDate: '2020-01-08T20:00:00+00:00',
            activity: {
              id: 55,
              name: 'Lomi Lomi 60',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: { name: 'Hale 1' },
          },
          {
            id: '73',
            startDate: '2020-01-08T22:00:00+00:00',
            endDate: '2020-01-08T23:00:00+00:00',
            activity: {
              id: 26,
              name: 'Lunch',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
          {
            id: '74',
            startDate: '2020-01-09T05:00:00+00:00',
            endDate: '2020-01-09T06:30:00+00:00',
            activity: {
              id: 45,
              name: 'Dinner',
              description: null,
              isAssessment: false,
              category: { id: 1, name: 'Uncategorized' },
            },
            location: null,
          },
        ],
        guideForBooking: {
          user: {
            id: '12',
            firstName: 'Quentin',
            lastName: 'Nichols',
            cellPhone: '8084521594',
          },
        },
        prearrival: {
          preferredWakeTime: '6am',
          mealTimes: ['no_breakfast', '12pm', '6pm'],
          intentions: [
            'improve_health',
            'relaxation',
            'new_life_stage',
            'time_with_loved_ones',
            'personal_reflection',
            'new_life_stage',
            'special_occasion',
            'new_life_stage',
            'improve_health',
            'special_occasion',
            'personal_reflection',
            'relaxation',
            'personal_reflection',
            'time_with_loved_ones',
          ],
          scheduleType: 'active',
        },

      },
    }
