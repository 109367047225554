import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ScreenResolution } from '../../types/resolution.type';
import { rescale } from '../ratio.util';
import ResolutionContext from '../../contexts/ResolutionContext';

function useStopwatch(startFrom?: Date) {
  const [running] = useState(true);
  const [elapsedTime, setElapsedTime] = useState(0); // in seconds

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (running) {
      interval = setInterval(() => {
        // disabled conditional logic for hotfix
        // if (startFrom) {
        //   setElapsedTime(Math.round((new Date() - startFrom) / 1000));
        // } else {
        setElapsedTime((prev) => prev + 1);
        //}
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [running, startFrom]);

  return {
    running,
    elapsedTime,
  };
}

type StopWatchContainerProps = {
  resolution: ScreenResolution;
  theme: {
    StopWatch: string;
  };
};
const StopWatchContainer = styled.div(
  ({ resolution, theme }: StopWatchContainerProps) => {
    return `
  color: ${theme.StopWatch};
  position: absolute;
  right: 0;
  bottom: ${rescale(-24, resolution, 'height')}px;
  font-size: ${rescale(64, resolution, 'height')}px;
  text-align: right;
  transition: 0.4s ease all;
`;
  }
);

StopWatchContainer.defaultProps = {
  theme: {
    StopWatch: 'rgba(255, 255, 255, 0.7)',
  },
};

function Stopwatch({ startFrom }: { startFrom?: Date }) {
  const { elapsedTime } = useStopwatch(startFrom);
  const resolution = useContext(ResolutionContext);

  function pad(n: number) {
    const p = '00';
    return (p + n).slice(-p.length);
  }

  const minutes = pad(Math.floor(elapsedTime / 60));
  const seconds = pad(elapsedTime % 60);
  return (
    <StopWatchContainer resolution={resolution}>
      {minutes}:{seconds}
    </StopWatchContainer>
  );
}

export default Stopwatch;
