import React from 'react';
import styled from 'styled-components';

type ImageBackgroundProps = {
  width: number;
  height: number;
  visible?: boolean;
  src?: string;
};
const ImageBackgroundContainer = styled.img(
  ({ width, height, visible }: ImageBackgroundProps) => {
    return `
  position: absolute;
  top: 0;
  width: ${width}px;
  height: ${height}px;
  opacity: ${visible ? 1 : 0};
  object-fit: cover;
  z-index: 1;
`;
  }
);

function ImageBackground(props: ImageBackgroundProps) {
  const { src, visible, height, width } = props;
  return (
    <ImageBackgroundContainer
      src={src}
      visible={visible}
      height={height}
      width={width}
    />
  );
}

export default ImageBackground;
