import React, { useContext } from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { DemoSessions } from './DemoSessions';
import { UpcomingAppointment } from '../../types/upcoming-appointment.type';
import { ScreenResolution } from '../../types/resolution.type';
import { rescale } from '../ratio.util';
import ResolutionContext from '../../contexts/ResolutionContext';

type ResolutionProps = {
  resolution: ScreenResolution;
};

const SessionsContainer = styled.div`
  margin: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 25%;
  z-index: 5;
  color: #fff;
`;

const Appointment = styled.a(({ resolution }: ResolutionProps) => {
  return `
  font-size: ${rescale(88, resolution, 'height')}px;
  background: #121212;
  border: 0;
  border-radius: ${rescale(24, resolution, 'width')}px;
  line-height: ${rescale(88, resolution, 'height')}px;
  padding: ${rescale(80, resolution, 'width')}px;
  margin: ${rescale(70, resolution, 'height')}px 0;
  cursor: pointer;
  color: #fff;
  display: block;
  text-decoration: none;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;
});

const AppointmentTime = styled.p(({ resolution }: ResolutionProps) => {
  return `
  font-size: ${rescale(64, resolution, 'height')}px;
  margin-top: ${rescale(16, resolution, 'height')}px;
  color: #888;
`;
});

const PickerContainer = styled.div(({ resolution }: ResolutionProps) => {
  return `
  margin: ${rescale(260, resolution, 'width')}px;
`;
});

const SectionHeading = styled.h3(({ resolution }: ResolutionProps) => {
  return `
  font-size: ${rescale(88, resolution, 'height')}px;
  font-weight: normal;
`;
});

const GuestPicker = styled.select(({ resolution }: ResolutionProps) => {
  return `
  font-size: ${rescale(88, resolution, 'height')}px;
  background: #121212;
  border: 0;
  border-radius: ${rescale(24, resolution, 'width')}px;
  line-height: ${rescale(120, resolution, 'height')}px;
  padding: ${rescale(80, resolution, 'width')}px;
  margin: ${rescale(70, resolution, 'height')}px 0;
  cursor: pointer;
  width: 100%;
  color: #888;
  -webkit-appearance: none;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;
});

const BlankStateText = styled.div(({ resolution }: ResolutionProps) => {
  return `
  font-size: ${rescale(88, resolution, 'height')}px;
  color: #888;
  margin: ${rescale(70, resolution, 'height')}px 0;
`;
});

type Props = {
  myUpcomingAppointments: UpcomingAppointment[] | null;
  otherUpcomingAppointments: UpcomingAppointment[] | null;
  onAppointmentSelect: (selectedAppointment: UpcomingAppointment) => void;
  onDemoSession: (property: number) => void;
};

function Sessions({
                    myUpcomingAppointments,
                    otherUpcomingAppointments,
                    onAppointmentSelect,
                    onDemoSession,
                  }: Props) {
  const hasAppointments =
    myUpcomingAppointments && myUpcomingAppointments.length > 0;
  const hasBookingParties =
    otherUpcomingAppointments && otherUpcomingAppointments.length > 0;
  const resolution = useContext(ResolutionContext);

  return (
    <div>
      <DemoSessions
        onDemoSession={(property: number) => onDemoSession(property)}
      />
      <SessionsContainer>
        <PickerContainer resolution={resolution}>
          <SectionHeading resolution={resolution}>
            My Upcoming Sessions
          </SectionHeading>
          {hasAppointments &&
            myUpcomingAppointments.map((appointment) => {
              return (
                <Appointment
                  resolution={resolution}
                  href='#'
                  key={appointment.id}
                  onClick={() => {
                    onAppointmentSelect(appointment);
                  }}
                >
                  {appointment.bookingParty.member.user.firstName}{' '}
                  {appointment.bookingParty.member.user.lastName}
                  <AppointmentTime resolution={resolution}>
                    {DateTime.fromISO(appointment.startDate)
                      .setLocale('en')
                      .toFormat('LLL dd h:mm a')}{' '}
                  </AppointmentTime>
                </Appointment>
              );
            })}
          {!hasAppointments && (
            <BlankStateText resolution={resolution}>
              No Guests Assigned
            </BlankStateText>
          )}
        </PickerContainer>
        <PickerContainer resolution={resolution}>
          <SectionHeading resolution={resolution}>All Sessions</SectionHeading>
          {hasBookingParties && (
            <GuestPicker
              resolution={resolution}
              defaultValue=''
              onChange={(e) => {
                const appointment = otherUpcomingAppointments?.find(
                  (appointment) => appointment.id === e.currentTarget.value,
                );
                if (appointment) {
                  onAppointmentSelect(appointment);
                }
              }}
            >
              <option value=''>Choose from an unassigned guest&hellip;</option>
              {otherUpcomingAppointments.map((appointment) => {
                return (
                  <option key={appointment.id} value={appointment.id}>
                    {appointment.bookingParty.member.user.firstName}{' '}
                    {appointment.bookingParty.member.user.lastName}
                  </option>
                );
              })}
            </GuestPicker>
          )}
          {!hasBookingParties && (
            <BlankStateText resolution={resolution}>
              No Guests Assigned
            </BlankStateText>
          )}
        </PickerContainer>
      </SessionsContainer>
    </div>
  );
}

export default Sessions;
