import React, { useContext, useState } from 'react';
import { useKey } from 'react-use';
import styled from 'styled-components';
import { CurrentPose } from 'react-pose/lib/components/PoseElement/types';
import ResolutionContext from '../../contexts/ResolutionContext';
import { rescale } from '../ratio.util';
import { ScreenResolution } from '../../types/resolution.type';

type Props = {
  lineHeight: number;
  resolution: ScreenResolution;
};
const ScrollableDiv = styled.div(({ lineHeight, resolution }: Props) => {
  return `
    overflow-y: auto;
    line-height: ${lineHeight}px;
    height: ${rescale(1340, resolution, 'height')}px;
  `;
});

export const IntentionList = ({
  pose,
  intentions,
}: {
  pose?: CurrentPose;
  intentions?: string;
}) => {
  const resolution = useContext(ResolutionContext);
  const lineHeight = rescale(134, resolution, 'height');
  const [scrollFromTop, setScrollFromTop] = useState<number>(0);
  const scrollableDiv = React.useRef<HTMLDivElement>(null);
  useKey(
    'ArrowDown',
    () => {
      if (
        pose === 'visible' &&
        scrollableDiv.current &&
        scrollableDiv.current.scrollTop + scrollableDiv.current.clientHeight <
          scrollableDiv.current.scrollHeight
      ) {
        scrollableDiv.current.scrollTo({ top: scrollFromTop + lineHeight });
        setScrollFromTop(scrollFromTop + lineHeight);
      }
    },
    {},
    [pose, scrollableDiv, scrollFromTop, setScrollFromTop]
  );
  useKey(
    'ArrowUp',
    () => {
      if (pose === 'visible' && scrollFromTop > 0 && scrollableDiv.current) {
        scrollableDiv.current.scrollTo({ top: scrollFromTop - lineHeight });
        setScrollFromTop(scrollFromTop - lineHeight);
      }
    },
    {},
    [pose, scrollableDiv, scrollFromTop, setScrollFromTop]
  );
  return (
    <ScrollableDiv
      resolution={resolution}
      ref={scrollableDiv}
      lineHeight={lineHeight}
      style={{ overflow: 'hidden' }}
    >
      {intentions}
    </ScrollableDiv>
  );
};
