import React, { useContext } from 'react';
import styled from 'styled-components';
import { ScreenResolution } from '../../types/resolution.type';
import { rescale } from '../ratio.util';
import ResolutionContext from '../../contexts/ResolutionContext';

type MinimapContainerProps = {
  resolution: ScreenResolution;
  visible?: boolean;
};
export const MinimapContainer = styled.div(
  ({ resolution, visible }: MinimapContainerProps) => {
    return `
  opacity: ${visible ? 1 : 0};
  position: absolute;
  padding-left:  ${rescale(240, resolution, 'width')}px;
  padding-right: ${rescale(240, resolution, 'width')}px;
  width: 25%;
  bottom: ${rescale(472, resolution, 'height')}px;
  transform-origin: left center;
  will-change: transform;
  transform: translateY(${visible ? 0 : rescale(60, resolution, 'height')}px);
  transition: ${visible ? 0.5 : 0}s ease all;
  transition-delay: ${visible ? 0.5 : 0}s;
  z-index: 3;
`;
  }
);

type MinimapBorderProps = {
  resolution: ScreenResolution;
};
export const MinimapBorder = styled.div(
  ({ resolution }: MinimapBorderProps) => {
    return `
  margin-bottom: ${rescale(120, resolution, 'height')}px;
  width: 100%;
  border-top: ${rescale(
    3,
    resolution,
    'height'
  )}px solid rgba(255, 255, 255, 0.15);
`;
  }
);

type MinimapItemType = {
  resolution: ScreenResolution;
  active: boolean;
  theme: {
    MinimapItemActive: string;
    MinimapItem: string;
  };
};

export const MinimapItem = styled.div(
  ({ resolution, active, theme }: MinimapItemType) => {
    return `
  color: ${active ? theme.MinimapItemActive : theme.MinimapItem};
  margin-bottom: ${rescale(50, resolution, 'height')}px;
  display: flex;
  font-size: ${rescale(64, resolution, 'height')}px;

  &:last-child {
    margin-bottom: 0;
  }

  transition: 0.5s ease all;
`;
  }
);

export const MinimapTime = styled.div`
  flex-shrink: 0;
  width: 23%;
  line-height: 1.4;
  text-transform: lowercase;
`;

export const MinimapEvent = styled.div`
  line-height: 1.4;
`;

function Minimap() {
  const resolution = useContext(ResolutionContext);
  return (
    <MinimapContainer resolution={resolution}>
      <MinimapItem active={true} resolution={resolution}>
        <MinimapTime>8:00 am</MinimapTime>
        <MinimapEvent>Sensei Signature Massage</MinimapEvent>
      </MinimapItem>
      <MinimapItem active={false} resolution={resolution}>
        <MinimapTime>8:00 am</MinimapTime>
        <MinimapEvent>Manicure and Pedicure</MinimapEvent>
      </MinimapItem>
    </MinimapContainer>
  );
}

export default Minimap;
