import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import logoWhite from '../assets/logo-white.svg';
import { rescale } from './ratio.util';
import { ScreenResolution } from '../types/resolution.type';
import ResolutionContext from '../contexts/ResolutionContext';

type LogoImageProps = {
  resolution: ScreenResolution;
  theme: {
    LogoImage: string;
    LogoTitle: string;
  };
};

const LogoTitle = styled.div(({ resolution, theme }: LogoImageProps) => {
  return `position: absolute;
  top: ${rescale(resolution.is16x9 ? 298 : 310, resolution, 'height')}px;
  left: calc(${rescale(240, resolution, 'width')}px + ${rescale(
    320,
    resolution,
    'width'
  )}px + ${rescale(128, resolution, 'width')}px);
  color: ${theme.LogoTitle};
  font-size: ${rescale(88, resolution, 'height')}px;
  transition: 0.5s ease color;
  transition-delay: 0.3s;
  z-index: 3;
`;
});

const transition = {
  type: 'spring',
};
const LogoTitleAnim = posed(LogoTitle)({
  visible: {
    opacity: 1,
    x: 0,
    transition,
  },
  hidden: {
    opacity: 0,
    x: -50,
    transition,
  },
});

LogoTitle.defaultProps = {
  theme: {
    LogoTitle: '#fff',
  },
};

const LogoImage = styled.div(({ resolution, theme }: LogoImageProps) => {
  return `
  position: absolute;
  margin-top: ${rescale(320, resolution, 'height')}px;
  margin-left: ${rescale(240, resolution, 'width')}px;
  width: ${rescale(324, resolution, 'width')}px;
  height: ${rescale(64, resolution, 'height')}px;
  mask-image: url(${logoWhite});
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: ${theme.LogoImage};
  transition: 0.4s ease background-color;
  transition-delay: 0.3s;
  z-index: 3;
`;
});

LogoImage.defaultProps = {
  theme: {
    LogoImage: '#fff',
  },
};

function usePrevious(value?: string) {
  const ref = useRef<string>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

type Props = {
  title?: string;
};

function Logo(props: Props) {
  const { title } = props;
  const prevTitle = usePrevious(title);
  const resolution = useContext(ResolutionContext);
  return (
    <>
      <LogoImage resolution={resolution} />
      <LogoTitleAnim
        pose={title !== '' ? 'visible' : 'hidden'}
        resolution={resolution}
      >
        {title !== '' ? title : prevTitle}
      </LogoTitleAnim>
    </>
  );
}

export default Logo;
export { LogoImage };
