import { PROPERTIES } from '../components/constants';
import { Property } from '../types/property.type';

export const assetMapper = (propertyId: number): Property => {
  switch (propertyId) {
    case PROPERTIES.PORCUPINE_CREEK:
      return {
        introSrc: require('../assets/video/PC_VideoWall_Intro_Final.mp4'),
        outroScr: require('../assets/video/PC_VideoWall_Outro_Final.mp4'),
        map: {
          src: require('../assets/pc_map_medium_crop_no_legend.webp'),
          name: 'Porcupine Creek',
          legend: [
            { number: '1 - 5', name: 'Villas' },
            { number: '10', name: 'Golf Clubhouse' },
            { number: '11', name: 'Tennis Pavilion' },
            { number: '12', name: 'Estate House' },
            { number: '13', name: 'Casitas' },
            { number: '14', name: 'Fitness Pavilion' },
            { number: '15', name: 'Garden Treatment Rooms' },
            { number: '16', name: 'Yoga Pavilion' },
            { number: '17', name: 'Pool' },
          ],
        },
        experienceEmail: 'experiences-porcupinecreek@sensei.com',
      };
    case PROPERTIES.LANAI:
    default:
      return {
        introSrc: require('../assets/video/sweetheart-rock.mp4'),
        outroScr: require('../assets/video/drone.mp4'),
        map: {
          src: require('../assets/map2.webp'),
          name: 'Lānaʻi at Kōʻele',
          legend: [
            { number: '00-10', name: 'Spa Hales' },
            { number: '12', name: 'Movement Studio' },
            { number: '13', name: 'Exercise Pavilion' },
            { number: '16', name: 'The Great Hall' },
            { number: '18', name: 'Library' },
            { number: '19', name: 'Kōʻele Garden Bar' },
            { number: '21', name: 'Sensei by Nobu' },
            { number: '22', name: 'Yoga Pavilion' },
          ],
        },
        experienceEmail: 'experiences-lanai@sensei.com',
      };
  }
};
