import React, { useContext } from 'react';
import styled from 'styled-components';
import { ListItem, ListSeparator } from '../List';
import ResolutionContext from '../../contexts/ResolutionContext';
import { ScreenResolution } from '../../types/resolution.type';
import { rescale } from '../ratio.util';

type ButtonProps = {
  resolution: ScreenResolution;
};
const Button = styled.button(({ resolution }: ButtonProps) => {
  return `
  cursor: pointer;
  padding: 0 ${rescale(120, resolution, 'width')}px;
  right: ${rescale(260, resolution, 'width')}px;
  bottom: ${rescale(280, resolution, 'width')}px;
  height: ${rescale(287, resolution, 'height')}px;
  color: rgba(255, 255, 255, 0.6);
  font-size: ${rescale(88, resolution, 'height')}px;
  background: rgba(255, 255, 255, 0.1);
  border: 0;
  border-radius: ${rescale(24, resolution, 'width')}px;;
  outline: 0;
  transition: 0.2s ease all;
  z-index: 10;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;
});

type Props = {
  bookingPartyId: string;
  onAssign: (bookingPartyId: string) => Promise<void>;
  error: boolean;
};

function ReassignToGuide({ onAssign, error, bookingPartyId }: Props) {
  const resolution = useContext(ResolutionContext);

  return (
    <>
      <ListSeparator
        style={{ background: 'rgba(255, 255, 255, 0.1) ' }}
        resolution={resolution}
      />
      <ListItem
        style={{ color: error ? 'red' : 'rgba(255, 255, 255, 0.6)' }}
        resolution={resolution}
      >
        {error
          ? 'Apologies — this isn’t working at the moment. Please log into GMS and assign the guest to you'
          : 'The selected guest is not assigned to you'}
      </ListItem>
      <Button resolution={resolution} onClick={() => onAssign(bookingPartyId)}>
        Assign the Guest to Me
      </Button>
    </>
  );
}

export default ReassignToGuide;
