import React, { useContext, useEffect, useState } from 'react';
import posed from 'react-pose';
import styled, { ThemeProvider } from 'styled-components';
import { useDebounce, useKey } from 'react-use';
import { DateTime } from 'luxon';

import Text from '../Text';
import Logo from '../Logo';
import Sidebar from '../Sidebar/SidebarPerf';
import { List, ListItem } from '../List';
import ImageBackground from '../ImageBackground';
import { VideoBackgroundContainer } from '../VideoBackground';
import { DayBadge, DayBadgeName, DayBadgeNumber } from '../DayBadge';
import WallProgress from './WallProgress';
import Stopwatch from '../Stopwatch';
import WallContext from '../../contexts/WallContext';
import Dots from '../Dots';

// Minimap
import {
  MinimapBorder,
  MinimapContainer,
  MinimapEvent,
  MinimapItem,
  MinimapTime,
} from './Minimap';
import { AppointmentType } from '../../types/appointment.type';
import { Slide } from '../../types/slide.type';
import { Taxonomy } from '../../types/taxonomy.type';
import ResolutionContext from '../../contexts/ResolutionContext';
import { rescale } from '../ratio.util';
import { ScreenResolution } from '../../types/resolution.type';

type TempPosedContainerProps = {
  resolution: ScreenResolution;
};
const TempPosedContainer = styled.div(
  ({ resolution }: TempPosedContainerProps) => {
    return `
  position: absolute;
  top: 0;
  left: 0;
  right: ${rescale(240, resolution, 'width')}px;
`;
  }
);
const TempPosed = posed(TempPosedContainer)({
  visible: {
    opacity: 1,
    staggerChildren: 10,
    delayChildren: 420,
  },
  before: { opacity: 0 },
  after: { opacity: 0 },
});

type AlignTopProps = {
  resolution: ScreenResolution;
};

const AlignTop = styled.div(({ resolution }: AlignTopProps) => {
  return `
  position: absolute;
  top: ${rescale(800, resolution, 'height')}px;
  width: 25%;
  padding-left: ${rescale(240, resolution, 'width')}px;
  z-index: 3;
`;
});

const light = {
  LogoImage: '#000',
  LogoTitle: '#000',

  SidebarOpacity: 1,
  SidebarBackground: '#fff',
  ProgressBar: '#000',
  ProgressDot: 'rgba(0, 0, 0, 0.7)',
  StopWatch: 'rgba(0, 0, 0, 0.7)',

  DayBadge: '#000',
  DayBadgeName: '#fff',
  DayBadgeNumber: '#fff',

  DayListColor: '#000',

  MinimapItem: 'rgba(0, 0, 0, 0.3)',
  MinimapItemActive: 'rgba(0, 0, 0, 1)',
};
const dark = {
  LogoImage: '#fff',
  LogoTitle: '#fff',

  SidebarOpacity: 0.7,
  SidebarBackground: '#000',
  ProgressBar: '#fff',
  ProgressDot: 'rgba(255, 255, 255, 0.7)',
  StopWatch: 'rgba(255, 255, 255, 0.7)',

  DayBadge: '#fff',
  DayBadgeName: '#686868',
  DayBadgeNumber: '#343434',

  DayListColor: 'rgba(255, 255, 255, 0.7)',

  MinimapItem: 'rgba(255, 255, 255, 0.2)',
  MinimapItemActive: 'rgba(255, 255, 255, 1)',
};

const BackgroundItem = posed.div({
  visible: {
    applyAtStart: {
      opacity: 1,
      width: 0,
      left: '25%',
      zIndex: 2,
    },
    width: ({ width }: { width: number }) => {
      return width - width * 0.25;
    },
    transition: {
      type: 'tween',
      duration: 600,
      ease: [0.77, 0, 0.175, 1],
    },
  },
  hidden: {
    applyAtStart: {
      zIndex: 1,
    },
    opacity: 0,
    transition: {
      type: 'tween',
      duration: 800,
      ease: [0.77, 0, 0.175, 1],
    },
  },
});

const ThemeFade = posed.div({
  light: {
    opacity: 1,
    transition: ({ from, to }: { from: number; to: number }) => ({
      type: 'keyframes',
      values: [from, 0, 0, to],
      times: [0, 0.25, 0.5, 1],
      duration: 1500,
    }),
  },
  dark: {
    opacity: 1,
    transition: ({ from, to }: { from: number; to: number }) => ({
      type: 'keyframes',
      values: [from, 0, 0, to],
      times: [0, 0.25, 0.5, 1],
      duration: 1500,
    }),
  },
});

type DayListProps = { events: AppointmentType[]; pose: string };

function DayList({ events, pose }: DayListProps) {
  const resolution = useContext(ResolutionContext);
  return (
    <TempPosed pose={pose} resolution={resolution}>
      <List
        style={{ marginTop: rescale(160, resolution, 'height') }}
        resolution={resolution}
      >
        {events.map((e, i) => {
          return (
            <ListItem key={`${e.startDate}-${i}`} resolution={resolution}>
              <Text
                resolution={resolution}
                size="p"
                style={{
                  color: '#000',
                  textTransform: 'lowercase',
                }}
              >
                {DateTime.fromISO(e.startDate)
                  .setLocale('en')
                  .toFormat('h:mm a')}
              </Text>
              <Text
                size="p"
                style={{ color: '#000', maxWidth: '66%' }}
                resolution={resolution}
              >
                {e.activity.name}
              </Text>
            </ListItem>
          );
        })}
      </List>
    </TempPosed>
  );
}

type DayWallProps = {
  onEnd: () => void;
  onPrev: () => void;
  active: boolean;
  doReset: boolean;
};

function DayWall({ onEnd, onPrev, active, doReset }: DayWallProps) {
  const resolution = useContext(ResolutionContext);
  const [progress, setProgress] = useState(0); // Index starts at 0
  const [theme, setTheme] = useState(light);
  const [slides, setSlides] = useState<Partial<Slide>[]>([]);
  const [taxonomy, setTaxonomy] = useState<Taxonomy[]>([]);
  const [debouncedBg, setDebouncedBg] = useState<string | undefined>('');

  const data = useContext(WallContext);
  const days = data.days;

  const totalSlides = slides.length;
  const currentSlide = slides[progress];

  useDebounce(
    () => {
      setDebouncedBg(currentSlide.background);
    },
    230,
    [currentSlide]
  );

  // Fetch taxonomy
  useEffect(() => {
    console.log(
      `Fetching asset list from ${process.env.REACT_APP_ASSET_SERVER}`
    );

    async function fetchTaxonomy() {
      try {
        const res = await fetch(`${process.env.REACT_APP_ASSET_SERVER}/list`, {
          method: 'GET',
        });
        const json = await res.json();
        setTaxonomy(json);
      } catch (e) {
        console.error(e);
      }
    }

    fetchTaxonomy();
  }, []);

  // Set up backgrounds
  useEffect(() => {
    if (taxonomy.length === 0) return;

    function findBackground(slide: Partial<Slide>): Taxonomy | undefined {
      const type = slide.type;
      if (type === 'list') {
        const bgs = taxonomy.filter((x) => x.type === 'other');
        return bgs[Math.floor(Math.random() * bgs.length)];
      }
      if (type === 'event') {
        let bgs = [];
        bgs = taxonomy.filter(
          (x) => x.type === 'activity' && x.id === slide.activityId
        );
        if (bgs.length > 0) return bgs[Math.floor(Math.random() * bgs.length)];

        bgs = taxonomy.filter(
          (x) => x.type === 'category' && x.id === slide.categoryId
        );
        if (bgs.length > 0) return bgs[Math.floor(Math.random() * bgs.length)];

        bgs = taxonomy.filter((x) => x.type === 'other');
        return bgs[Math.floor(Math.random() * bgs.length)];
      }
    }

    if (days) {
      let s: Partial<Slide>[] = [];
      days.forEach((day, i) => {
        let listSlideData: Partial<Slide> = {
          type: 'list',
          day: i,
          badge: day.date,
          background: '',
          component: (props) => <DayList {...props} />,
        };

        // Set random background from taxonomy for list
        listSlideData.background = `${process.env.REACT_APP_ASSET_SERVER}${
          findBackground(listSlideData)?.path
        }`;

        s.push(listSlideData);
      });
      setSlides(s);
    }
  }, [data, days, taxonomy]);

  useEffect(() => {
    if (currentSlide && currentSlide.type === 'list') {
      setTheme(light);
    } else if (currentSlide) {
      setTheme(dark);
    }
  }, [currentSlide]);

  useKey(
    'ArrowLeft',
    () => {
      if (active) {
        setProgress((p) => {
          if (p === 0) {
            if (onPrev) onPrev();
          }
          return Math.max(--p, 0);
        });
      }
    },
    {},
    [active, totalSlides, currentSlide]
  );

  useKey(
    'ArrowRight',
    () => {
      if (active) {
        setProgress((p) => {
          if (p === totalSlides - 1) {
            if (onEnd) onEnd();
          }
          return Math.min(++p, totalSlides - 1);
        });
      }
    },
    {},
    [active, totalSlides]
  );

  useEffect(() => {
    if (doReset) setProgress(0);
  }, [doReset]);

  if (slides.length === 0 || !currentSlide || !days) return null;
  const uniqueBgs = new Set(slides.map((s) => s.background));
  const isList = currentSlide.type !== 'list';

  return (
    <ThemeProvider theme={theme}>
      <div
        className="wall"
        style={{ width: resolution.width, height: resolution.height }}
      >
        <ThemeFade
          pose={currentSlide.type === 'list' ? 'light' : 'dark'}
          style={{ position: 'absolute', width: '100%', zIndex: 3 }}
        >
          <Logo title={'My Stay'} />
          <AlignTop resolution={resolution}>
            <DayBadge key={'DayBadge'} resolution={resolution}>
              <DayBadgeName resolution={resolution}>
                {currentSlide.badge?.split(' ')[0]}
              </DayBadgeName>
              <DayBadgeNumber resolution={resolution}>
                {currentSlide.badge?.split(' ')[1]}
              </DayBadgeNumber>
            </DayBadge>
            <div style={{ position: 'relative', width: '100%' }}>
              {slides.map((s, index) => {
                const pose =
                  progress === index
                    ? 'visible'
                    : progress < index
                    ? 'before'
                    : 'after';
                return (
                  // @ts-ignore
                  <s.component
                    key={`daywall-slide-${index}`}
                    id={`daywall-slide-${index}`}
                    pose={pose}
                  />
                );
              })}
            </div>
          </AlignTop>
        </ThemeFade>

        <MinimapContainer visible={isList} resolution={resolution}>
          <MinimapBorder resolution={resolution} />
          {currentSlide.day
            ? days[currentSlide.day].events.map(
                (event: Partial<AppointmentType>, index: number) => {
                  return (
                    <MinimapItem
                      resolution={resolution}
                      key={`MinimapItem-${event.activity?.name}-${index}`}
                      active={currentSlide.name === event.activity?.name}
                    >
                      <MinimapTime>
                        {event.startDate
                          ? DateTime.fromISO(event.startDate)
                              .setLocale('en')
                              .toFormat('h:mm a')
                          : ''}
                      </MinimapTime>
                      <MinimapEvent>{event.activity?.name}</MinimapEvent>
                    </MinimapItem>
                  );
                }
              )
            : null}
        </MinimapContainer>
        <WallProgress
          resolution={resolution}
          style={{
            width: 'calc(25% - ' + rescale(480, resolution, 'width') + 'px)',
            bottom: rescale(264, resolution, 'height'),
            zIndex: 3,
          }}
        >
          <Dots progress={currentSlide.day} total={days ? days.length : 1} />
          <Stopwatch startFrom={data.logInTimestamp} />
        </WallProgress>
        <Sidebar dark={isList} />
        {Array.from(uniqueBgs).map((bg, index) => {
          if (bg?.includes('.mp4') || bg?.includes('webm')) {
            return (
              <BackgroundItem
                key={'videobg' + bg}
                pose={debouncedBg === bg ? 'visible' : 'hidden'}
                style={{
                  position: 'absolute',
                  top: 0,
                  overflow: 'hidden',
                  height: resolution.height,
                }}
              >
                <VideoBackgroundContainer
                  loop
                  muted
                  autoPlay
                  visible
                  width={resolution.width}
                  height={resolution.height}
                >
                  <source
                    src={bg}
                    type={bg?.includes('.mp4') ? 'video/mp4' : 'video/webm'}
                  />
                </VideoBackgroundContainer>
              </BackgroundItem>
            );
          } else {
            return (
              <BackgroundItem
                key={'imagebg' + bg}
                pose={debouncedBg === bg ? 'visible' : 'hidden'}
                style={{
                  position: 'absolute',
                  top: 0,
                  overflow: 'hidden',
                  height: resolution.height,
                }}
              >
                <ImageBackground
                  key={bg}
                  src={bg}
                  width={resolution.width}
                  height={resolution.height}
                  visible
                />
              </BackgroundItem>
            );
          }
        })}
        <VideoBackgroundContainer
          autoPlay
          loop
          muted
          visible
          width={resolution.width}
          height={resolution.height}
        >
          <source src={require('../../assets/video/h1.mp4')} />
        </VideoBackgroundContainer>
      </div>
    </ThemeProvider>
  );
}

export default DayWall;
