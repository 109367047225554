import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Player from 'react-player';
import ReactPlayer from 'react-player';
import { useKey } from 'react-use';

import Text from '../Text';
import { LogoImage } from '../Logo';
import { List, ListItem, ListSeparator } from '../List';
import Sessions from '../Sessions';
import WallContext from '../../contexts/WallContext';
import { hasLoginPermissions } from './auth.util';
import { logger } from '../../services/logger';
import { PreloadMessage } from '../PreloadMessage/PreloadMessage';
import { AssetContext } from '../../contexts/AssetContext';
import { demoSessionMock } from '../../helpers/demoSessionMock';
import styled from 'styled-components';
import createAuth0Client from '@auth0/auth0-spa-js';
import { ReactPlayerProgress } from '../../types/react-player-progress.type';
import { WallData } from '../../types/wall-data.type';
import {
  AllUpcomingAssignedAssessmentAppointmentsResult,
  UpcomingAppointment,
} from '../../types/upcoming-appointment.type';
import n from '../../assets/n.svg';
import ReassignToGuide from '../ReassignToGuide/ReassignToGuide';
import ResolutionContext from '../../contexts/ResolutionContext';
import { ScreenResolution } from '../../types/resolution.type';
import { rescale } from '../ratio.util';
import { User } from '../../types/guide.type';

type Visible = { visible: boolean };
const AuthContainer = styled.div(({ visible }: Visible) => {
  return `
    opacity: ${visible ? 1 : 0};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    transition: 1s ease all;
    transition-delay: 0.32s;
  `;
});
type AuthLogoProps = Visible & {
  resolution: ScreenResolution;
};
const AuthLogo = styled.div(({ resolution, visible }: AuthLogoProps) => {
  return `
  opacity: ${visible ? 1 : 0};
  position: absolute;
  width: ${rescale(1319, resolution, 'width')}px;
  height: ${rescale(1674, resolution, 'height')}px;
  margin-top: ${rescale(-8, resolution, 'height')}px;
  margin-left: ${rescale(1, resolution, 'width')}px;
  display: block;
  mask-image: url(${n});
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-border-mode: alpha;
  background: rgba(255, 255, 255, 0.08);
  z-index: 4;
  transition: 0.8s ease all;
  zoom: 1.06;
`;
});

type AuthSubmitProps = Visible & {
  resolution: ScreenResolution;
};
const AuthSubmit = styled.button(({ resolution, visible }: AuthSubmitProps) => {
  return `
  display: ${visible ? 'block' : 'none'};
  cursor: pointer;
  padding: ${rescale(64, resolution, 'height')}px ${rescale(
    80,
    resolution,
    'width'
  )}px;
  color: #656565;
  font-size: ${rescale(88, resolution, 'height')}px;
  background: #222;
  border: 0;
  border-radius: ${rescale(24, resolution, 'width')}px;
  outline: 0;
  transition: 0.2s ease all;

  &:hover {
    background: #333;
  }
`;
});

type AuthFormProps = Visible & {
  resolution: ScreenResolution;
};
const AuthForm = styled.form(({ resolution, visible }: AuthFormProps) => {
  return `
  pointer-events: ${visible ? 'auto' : 'none'};
  opacity: ${visible ? 1 : 0};
  position: absolute;
  right: ${rescale(260, resolution, 'width')}px;
  bottom: ${rescale(280, resolution, 'height')}px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  transition: 0.2s ease all;
  align-items: flex-end;
`;
});

type AuthInputProps = Visible & {
  resolution: ScreenResolution;
};
const AuthInput = styled.input(({ resolution, visible }: AuthInputProps) => {
  return `
  display: ${visible ? 'block' : 'none'};
  padding: ${rescale(64, resolution, 'height')}px ${rescale(
    80,
    resolution,
    'width'
  )}px;
  width: ${rescale(1596, resolution, 'width')}px;
  height: ${rescale(287, resolution, 'height')}px;
  font-size: ${rescale(88, resolution, 'height')}px;
  color: #888;
  background: #121212;
  border: 0;
  border-radius: ${rescale(24, resolution, 'width')}px;
  line-height: ${rescale(88, resolution, 'height')}px;
  outline: none;
  transition: 0.2s ease all;

  &:focus {
    background: #1a1a1a;
  }

  &:disabled {
    opacity: 0.6;
  }

  &::placeholder {
    color: #656565;
  }

  &::selection {
    color: #999;
    background: #888;
  }
`;
});

type AuthFormErrorProps = Visible & {
  resolution: ScreenResolution;
};
const AuthFormError = styled.div(
  ({ resolution, visible }: AuthFormErrorProps) => {
    return `
  opacity: ${visible ? 1 : 0};
  margin-bottom: calc(${rescale(287, resolution, 'height')}px / 2);
  color: #824747;
  font-size: ${rescale(88, resolution, 'height')}px;
  transform: translateY(${visible ? 0 : rescale(100, resolution, 'height')}px);
  transition: 0.3s ease all;
  white-space: pre-wrap;
`;
  }
);

type AuthGreetingProps = Visible & {
  resolution: ScreenResolution;
};

const AuthGreeting = styled.div(
  ({ resolution, visible }: AuthGreetingProps) => {
    return `
  opacity: ${visible ? 1 : 0};
  position: absolute;
  z-index: 4;
  width: 100%;
  padding-left: ${rescale(240, resolution, 'width')}px;
  transition: 1s ease all;
  will-change: opacity;
`;
  }
);

type AuthContinueProps = Visible & {
  resolution: ScreenResolution;
};

const AuthContinue = styled.button(
  ({ resolution, visible }: AuthContinueProps) => {
    return `
  position: absolute;
  opacity: ${visible ? 1 : 0};
  cursor: pointer;
  padding: 0 ${rescale(120, resolution, 'width')}px;
  pointer-events: ${visible ? 'auto' : 'none'};
  right: ${rescale(260, resolution, 'width')}px;
  bottom: ${rescale(280, resolution, 'height')}px;
  height: ${rescale(287, resolution, 'height')}px;
  color: rgba(255, 255, 255, 0.6);
  font-size: ${rescale(88, resolution, 'height')}px;
  background: rgba(255, 255, 255, 0.1);
  border: 0;
  border-radius: ${rescale(24, resolution, 'width')}px;
  outline: 0;
  transition: 0.2s ease all;
  z-index: 10;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;
  }
);

type Props = {
  onDone: (wallData: Partial<WallData>) => void;
  onData: (appointment: UpcomingAppointment) => void;
  doReset: boolean;
  onReset: () => void;
  active: boolean;
};

function Auth({ onDone, onData, doReset, onReset, active }: Props) {
  const doContinue = useRef(false);
  const [authed, setAuthed] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState<User>();
  const [selectedSession, setSelectedSession] = useState<UpcomingAppointment>();
  const [assignToGuideError, setAssignToGuideError] = useState<boolean>();
  const [authError, setAuthError] = useState('');
  const [wallData, setWallData] = useState<Partial<WallData>>({});
  const [introDone, setIntroDone] = useState(false);
  const [cont, setCont] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [myUpcomingAppointments, setMyUpcomingAppointments] = useState<
    UpcomingAppointment[] | null
  >(null);
  const [otherUpcomingAppointments, setOtherUpcomingAppointments] = useState<
    UpcomingAppointment[] | null
  >(null);
  const [jwtToken, setJwtToken] = useState(null);
  const [sessionSelected, setSessionSelected] = useState(false);

  const data = useContext<Partial<WallData>>(WallContext);
  const { setPropertyAssets, progress } = useContext(AssetContext);
  const resolution = useContext(ResolutionContext);

  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const shineRef = useRef<ReactPlayer>(null);

  const isSsoEnabled = `${process.env.REACT_APP_SSO_ENABLED}` === 'true';

  const reset = useCallback(() => {
    setAuthed(false);
    setAuthError('');
    setWallData({});
    setIntroDone(false);
    setCont(false);
    setSessionSelected(false);
    setOtherUpcomingAppointments(null);
    setMyUpcomingAppointments(null);
    setJwtToken(null);
    setSelectedSession(undefined);
    setLoggedInUser(undefined);
    setAssignToGuideError(false);
    doContinue.current = false;
    if (usernameRef.current) {
      usernameRef.current.value = '';
    }
    if (passwordRef.current) {
      passwordRef.current.value = '';
    }
    if (shineRef.current) {
      shineRef.current.seekTo(0, 'seconds');
    }
    onReset();
  }, [onReset]);

  function handleShineProgrees(progress: ReactPlayerProgress) {
    if (!introDone && doContinue.current && progress.playedSeconds > 9.91) {
      setIntroDone(true);
    }
  }

  async function credentialsAuth(username: string, password: string) {
    setIsLoading(true);

    const authRes = await fetch(
      `${process.env.REACT_APP_API_URL}/graphql/authenticate`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          operationName: 'tokenAuth',
          variables: {
            app: 'video_wall',
            email: username,
            password: password,
          },
          query: `
            mutation tokenAuth($email: String!, $password: String!, $app: String!) {
              tokenAuth(email: $email, password: $password, app: $app) {
                token
                user {
                  firstName
                  lastName
                }
              }
            }
        `,
        }),
      }
    );

    return await authRes.json();
  }

  async function ssoAuth() {
    const auth0 = await createAuth0Client({
      domain: `${process.env.REACT_APP_AUTH0_DOMAIN}`,
      client_id: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
    });

    try {
      return await auth0.getTokenWithPopup({
        audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
        scope: 'openid profile email video_wall',
        connection: `${process.env.REACT_APP_AUTH0_DEFAULT_CONNECTION}`,
      });
    } catch (e: any) {
      return { errors: [{ message: e?.error_description }] };
    }
  }

  async function authAndLoadSessions(
    username: string = '',
    password: string = ''
  ) {
    const authData = await (isSsoEnabled
      ? ssoAuth()
      : credentialsAuth(username, password));
    if (authData.errors && authData.errors.length) {
      return authData;
    }
    const token = isSsoEnabled ? authData : authData.data.tokenAuth.token;
    setIsLoading(true);
    setJwtToken(token);

    try {
      const meResp = await fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `jwt ${token}`,
        },
        body: JSON.stringify({
          query: `
             query queryMe {
              me {
                permissions
              }
            }`,
        }),
      });

      const meData = await meResp.json();

      if (meData.errors && meData.errors.length) {
        return meData;
      }

      const mePermissions = meData.data.me.permissions;

      if (!hasLoginPermissions(mePermissions)) {
        logger.info(
          `User does not have the required permissions for this application: ${mePermissions.join(
            ', '
          )}`
        );
        return {
          ...meData,
          errors: [
            new Error(
              `Login failed - missing required permissions.\nPlease contact the administrator.`
            ),
          ],
        };
      }

      const sessionsRes = await fetch(
        `${process.env.REACT_APP_API_URL}/graphql`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `jwt ${token}`,
          },
          body: JSON.stringify({
            variables: {
              memberFacingOnly: true,
            },
            query: `
             query videowallSessions($memberFacingOnly: Boolean) {
               me {
                id
                firstName
                lastName
                cellPhone
              }
              allUpcomingAssignedAssessmentAppointments {
                id
                startDate
                endDate
                activity {
                  id
                  name
                  description
                  isAssessment
                  isTravel
                  category {
                    id
                    name
                  }
                }
                location {
                  name
                }
                bookingParty {
                  id
                  guideForBooking {
                    user {
                      id
                      firstName
                      lastName
                      }
                    }
                  member {
                    user {
                      firstName
                      lastName
                    }
                  }
                  prearrival {
                    preferredWakeTime
                    mealTimes
                    intentions
                    scheduleType
                  }
                  appointments(memberFacingOnly: $memberFacingOnly) {
                    id
                    startDate
                    endDate
                    activity {
                      id
                      name
                      description
                      isAssessment
                      isTravel
                      category {
                        id
                        name
                      }
                    }
                    location {
                      name
                    }
                  }
                  booking {
                    startDate
                    endDate
                    property {
                      id
                    }
                  }
                }
              }
            }`,
          }),
        }
      );

      const sessionsData: AllUpcomingAssignedAssessmentAppointmentsResult = await sessionsRes.json();

      if (sessionsData.errors && sessionsData.errors.length) {
        return sessionsData;
      }

      setLoggedInUser(sessionsData.data.me);
      const myUpcomingAppointments = sessionsData.data.allUpcomingAssignedAssessmentAppointments.filter(
        (appointment) =>
          appointment.bookingParty.guideForBooking?.user?.id ===
          sessionsData.data.me.id
      );

      const otherUpcomingAppointments = sessionsData.data.allUpcomingAssignedAssessmentAppointments.filter(
        (appointment) =>
          appointment.bookingParty.guideForBooking?.user?.id !==
          sessionsData.data.me.id
      );

      setMyUpcomingAppointments(myUpcomingAppointments);
      setOtherUpcomingAppointments(otherUpcomingAppointments);
      return null;
    } catch (error) {
      logger.error(error);
      return { errors: [error] };
    }
  }

  function loadDemoData(property: number) {
    setSessionSelected(true);
    handleWallData(demoSessionMock(property));
  }

  async function setSelectedAppointment(
    selectedAppointment: UpcomingAppointment
  ) {
    setSelectedSession(selectedAppointment);
    setSessionSelected(true);
    handleWallData(selectedAppointment);
  }

  async function assignGuestToLoggedInGuide(
    bookingPartyId: string | undefined
  ) {
    if (!bookingPartyId) {
      return;
    }

    const assignGuestRes = await fetch(
      `${process.env.REACT_APP_API_URL}/graphql`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `jwt ${jwtToken}`,
        },
        body: JSON.stringify({
          variables: {
            bookingPartyId: bookingPartyId,
          },
          query: `
          mutation assignLoggedInGuideToBookingParty($bookingPartyId: ID!) {
            assignLoggedInGuideToBookingParty (bookingPartyId: $bookingPartyId){
              __typename
            }
          }
          `,
        }),
      }
    );

    const result = await assignGuestRes.json();
    if (result.errors || !loggedInUser) {
      setAssignToGuideError(true);
    } else {
      setAssignToGuideError(false);
      if (selectedSession) {
        selectedSession.bookingParty.guideForBooking.user = loggedInUser;
        setSelectedAppointment(selectedSession);
        onData(selectedSession);
      }
    }
  }

  const handleWallData = (appointment: UpcomingAppointment) => {
    if (setPropertyAssets) {
      setPropertyAssets(appointment.bookingParty.booking?.property?.id || 1);
    }
    onData(appointment);
  };

  async function handleAuth(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const username = usernameRef.current?.value;
    const password = passwordRef.current?.value;
    const res = await authAndLoadSessions(username, password);
    setIsLoading(false);
    if (res && res.errors) {
      setAuthError(res.errors[0].message);
    } else {
      usernameRef.current?.blur();
      passwordRef.current?.blur();
      setAuthed(true);
    }
  }

  useEffect(() => {
    if (doReset) {
      reset();
    }
  }, [doReset, reset]);

  useEffect(() => {
    // Send over data sooner
    if (introDone) onDone(wallData);
  }, [introDone, onDone, wallData]);

  useKey(
    'ArrowRight',
    () => {
      if (active && sessionSelected) {
        setIntroDone(true);
        setCont(true);
      }
    },
    {},
    [active, sessionSelected]
  );

  useKey(
    'ArrowLeft',
    () => {
      if (active && authed) {
        reset();
      }
    },
    {},
    [active, authed]
  );

  return (
    <AuthContainer visible={!introDone}>
      <AuthForm
        onSubmit={(event) => handleAuth(event)}
        visible={!authed}
        action=""
        resolution={resolution}
      >
        <AuthFormError visible={authError !== ''} resolution={resolution}>
          {authError}
        </AuthFormError>
        <AuthInput
          resolution={resolution}
          required={!isSsoEnabled}
          autoComplete="off"
          name="username"
          spellCheck="false"
          style={{ marginBottom: rescale(287, resolution, 'height') / 2 }}
          placeholder="Guide ID"
          ref={usernameRef}
          onChange={() => setAuthError('')}
          disabled={isLoading}
          visible={!isSsoEnabled}
        />
        <AuthInput
          resolution={resolution}
          required={!isSsoEnabled}
          autoComplete="off"
          name="password"
          type="password"
          placeholder="Password"
          ref={passwordRef}
          onChange={() => setAuthError('')}
          disabled={isLoading}
          visible={!isSsoEnabled}
        />
        <AuthSubmit
          visible={isSsoEnabled && !isLoading && !authed}
          resolution={resolution}
        >
          Sign In
        </AuthSubmit>
      </AuthForm>

      {authed && !sessionSelected && (
        <Sessions
          myUpcomingAppointments={myUpcomingAppointments}
          otherUpcomingAppointments={otherUpcomingAppointments}
          onAppointmentSelect={(appointment: UpcomingAppointment) =>
            setSelectedAppointment(appointment)
          }
          onDemoSession={(property) => loadDemoData(property)}
        />
      )}

      <AuthContinue
        resolution={resolution}
        visible={authed && sessionSelected && !cont}
        onClick={() => {
          setIntroDone(true);
        }}
      >
        Continue
      </AuthContinue>

      <LogoImage
        style={{ position: 'absolute', top: 0, left: 0, zIndex: 3 }}
        resolution={resolution}
      />

      <AuthGreeting
        visible={!introDone && sessionSelected}
        resolution={resolution}
      >
        <Text
          resolution={resolution}
          size="h2"
          style={{
            marginTop: resolution.is16x9
              ? rescale(100, resolution, 'height')
              : rescale(200, resolution, 'height'),
          }}
        >
          <span style={{ fontWeight: 500 }}>Welcome</span>
        </Text>
        <Text resolution={resolution} size="h2" style={{ marginBottom: 48 }}>
          <span style={{ fontWeight: 500 }}>{data.guestName}</span>
        </Text>
        <List
          style={{
            width: 'calc(25% - ' + rescale(480, resolution, 'width') + 'px)',
            marginTop: 0,
          }}
          resolution={resolution}
        >
          <ListSeparator
            style={{
              background: 'rgba(255, 255, 255, 0.1) ',
              visibility: resolution.is16x9 ? 'hidden' : 'visible',
            }}
            resolution={resolution}
          />
          {data.guideName && (
            <ListItem resolution={resolution}>
              <Text
                resolution={resolution}
                size="p"
                style={{ color: 'rgba(255, 255, 255, 0.6)' }}
              >
                Guide
              </Text>
              <Text resolution={resolution} size="p" style={{ color: '#fff' }}>
                {data.guideName}
              </Text>
            </ListItem>
          )}

          {data.assessment && data.assessment.start && (
            <ListItem resolution={resolution}>
              <Text
                resolution={resolution}
                size="p"
                style={{ color: 'rgba(255, 255, 255, 0.6)' }}
              >
                Start
              </Text>
              <Text resolution={resolution} size="p" style={{ color: '#fff' }}>
                {data.assessment.start}
              </Text>
            </ListItem>
          )}

          {data.assessment && data.assessment.location && (
            <ListItem resolution={resolution}>
              <Text
                resolution={resolution}
                size="p"
                style={{ color: 'rgba(255, 255, 255, 0.6)' }}
              >
                Room
              </Text>
              <Text resolution={resolution} size="p" style={{ color: '#fff' }}>
                {data.assessment.location}
              </Text>
            </ListItem>
          )}

          {selectedSession?.bookingParty.id &&
            selectedSession?.bookingParty.guideForBooking.user.id !==
              loggedInUser?.id && (
              <ReassignToGuide
                bookingPartyId={selectedSession?.bookingParty.id}
                onAssign={assignGuestToLoggedInGuide}
                error={!!assignToGuideError}
              />
            )}
        </List>
      </AuthGreeting>

      <AuthLogo visible={!sessionSelected} resolution={resolution} />

      <Player
        url={require('../../assets/video/shine.mp4')}
        muted
        loop={!introDone}
        width="100%"
        height="100%"
        style={{
          zIndex: 2,
        }}
        progressInterval={50}
        playing={sessionSelected}
        onProgress={handleShineProgrees}
        ref={shineRef}
      />
      {sessionSelected && (
        <PreloadMessage resolution={resolution}>
          {progress?.percent}
        </PreloadMessage>
      )}
    </AuthContainer>
  );
}

export default Auth;
