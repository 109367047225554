// ASPECT RATIO: 135:64 (16:7.5851851851851855)
import { ScreenResolution } from '../types/resolution.type';

export const SUPPORTED_RESOLUTIONS: ScreenResolution[] = [
  {
    // ASPECT RATIO: 135:64 (16:7.5851851851851855)
    // Do not change the first item because it is referenced in other places as
    width: 8640,
    height: 4096,
    name: '8k',
    is16x9: false,
  },
  {
    // ASPECT RATIO: 16:9
    width: 3840,
    height: 2160,
    name: '4k',
    is16x9: true,
  },
  {
    // ASPECT RATIO: 16:9
    width: 1920,
    height: 1080,
    name: '4k',
    is16x9: true,
  },
  {
    // This is used to detect the currently used screen
    width: 0,
    height: 0,
    name: 'current',
  },
];

export const PROPERTIES = {
  LANAI: 1,
  PORCUPINE_CREEK: 2,
};
