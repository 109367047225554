import { captureException, captureMessage } from '@sentry/browser';

export const logger = {
  error(error: Error | unknown) {
    captureException(error);
  },
  info(message: string) {
    captureMessage(message);
  },
};
