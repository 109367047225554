import { SUPPORTED_RESOLUTIONS } from './constants';
import { ScreenResolution } from '../types/resolution.type';

/**
 * Proportional conversion of a given value depending on the selected resolution.
 *
 * @param sizeToRescale Value we want to transform
 * @param selectedResolution Selected resolution
 * @param calculationBasis Ratio Calculation basis. Width or Height
 * @returns The calculated value
 */
export const rescale = (
  sizeToRescale: number,
  selectedResolution: ScreenResolution,
  calculationBasis: 'width' | 'height'
): number => {
  const originalSize =
    calculationBasis === 'width'
      ? SUPPORTED_RESOLUTIONS[0].width
      : SUPPORTED_RESOLUTIONS[0].height;
  return selectedResolution[calculationBasis] * (sizeToRescale / originalSize);
};
