import React, { useContext } from 'react';
import styled from 'styled-components';
import { AssetContext } from '../contexts/AssetContext';
import ResolutionContext from '../contexts/ResolutionContext';

type Props = {
  width: number;
  height: number;
  visible: boolean;
};
export const VideoBackgroundContainer = styled.video(
  ({ width, height, visible }: Props) => {
    return `
  opacity: ${visible ? 1 : 0};
  position: absolute;
  top: 0;
  width: ${width}px;
  height: 100%;
  object-fit: cover;
  transition: 1.4s ease opacity;
  z-index: 0;
`;
  }
);

function VideoBackground(props: { type: string | number | undefined }) {
  const { type = 0 } = props;
  const { assets } = useContext(AssetContext);
  const resolution = useContext(ResolutionContext);
  return (
    <>
      <VideoBackgroundContainer
        autoPlay
        loop
        muted
        visible={type === 0}
        width={resolution.width}
        height={resolution.height}
      >
        <source src={require('../assets/video/h1.mp4')} />
      </VideoBackgroundContainer>
      <VideoBackgroundContainer
        autoPlay
        loop
        muted
        visible={type === 1}
        width={resolution.width}
        height={resolution.height}
      >
        <source src={require('../assets/video/h2.mp4')} />
      </VideoBackgroundContainer>
      <VideoBackgroundContainer
        autoPlay
        loop
        muted
        visible={type === 2}
        width={resolution.width}
        height={resolution.height}
      >
        <source src={require('../assets/video/h3.mp4')} />
      </VideoBackgroundContainer>
      <VideoBackgroundContainer
        autoPlay
        loop
        muted
        visible={type === 3}
        width={resolution.width}
        height={resolution.height}
      >
        <source src={require('../assets/video/h4.mp4')} />
      </VideoBackgroundContainer>
      <VideoBackgroundContainer
        autoPlay
        loop
        muted
        visible={type === 4}
        width={resolution.width}
        height={resolution.height}
      >
        <source src={assets?.introSrc} />
      </VideoBackgroundContainer>
    </>
  );
}

export default VideoBackground;
