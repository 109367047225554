import React, { useContext } from 'react';
import styled from 'styled-components';
import posed from 'react-pose';

import Text from './Text';
import WallContext from '../contexts/WallContext';
import ResolutionContext from '../contexts/ResolutionContext';
import { ScreenResolution } from '../types/resolution.type';
import { rescale } from './ratio.util';

type TitleContainerProps = {
  resolution: ScreenResolution;
  visible?: boolean;
};
const TitleContainer = styled.div(
  ({ resolution, visible }: TitleContainerProps) => {
    return `
  opacity: ${visible ? 1 : 0};
  position: absolute;
  top: ${rescale(320, resolution, 'height')}px;
  right: ${rescale(240, resolution, 'width')}px;
  text-align: right;
  z-index: 2;
`;
  }
);

type Props = {
  visible?: boolean;
};
const TitleForward = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const data = useContext(WallContext);
  const resolution = useContext(ResolutionContext);
  return (
    <TitleContainer ref={ref} visible={props.visible} resolution={resolution}>
      <Text size="p" resolution={resolution}>
        {data.guestName}
        <br />
        with {data.guideName}
      </Text>
    </TitleContainer>
  );
});

const transition = {
  type: 'spring',
};
const TitleAnim = posed(TitleForward)({
  visible: {
    opacity: 1,
    x: 0,
    transition,
  },
  hidden: {
    opacity: 0,
    x: 100,
    transition,
  },
});

export default TitleAnim;
