import React, { useContext, useState } from 'react';
import { useKey } from 'react-use';

import WallContext from '../../contexts/WallContext';

import Logo from '../Logo';
import { VideoBackgroundContainer } from '../VideoBackground';
import Sidebar, { TopAlign } from '../Sidebar';
import Text from '../Text';
import Modal from '../Modal';
import { List, ListItem } from '../List';
import { AssetContext } from '../../contexts/AssetContext';
import ResolutionContext from '../../contexts/ResolutionContext';
import { rescale } from '../ratio.util';

type Props = {
  onEnd: () => void;
  onPrev: () => void;
  active: boolean;
};
const ThankYouWall = ({ onEnd, onPrev, active }: Props) => {
  const pose = 'visible';
  const data = useContext(WallContext);
  const resolution = useContext(ResolutionContext);
  const [showExitModal, setShowExitModal] = useState(false);
  const { assets } = useContext(AssetContext);

  useKey(
    'ArrowLeft',
    () => {
      if (active && showExitModal) {
        setShowExitModal(false);
      } else if (active && onPrev) {
        onPrev();
      }
    },
    {},
    [active, showExitModal]
  );

  useKey(
    'ArrowRight',
    () => {
      if (active && showExitModal) {
        if (onEnd) {
          onEnd();
        }
      } else {
        setShowExitModal(true);
      }
    },
    {},
    [active, showExitModal]
  );

  return (
    <div
      className="wall"
      style={{ width: resolution.width, height: resolution.height }}
    >
      <Logo title={''} />
      <Sidebar
        visible={true}
        expanded={false}
        opacity={0.7}
        resolution={resolution}
      >
        <TopAlign pose={pose} resolution={resolution}>
          <Text
            size="h2"
            style={{
              marginBottom: rescale(48, resolution, 'height'),
              marginTop: resolution.is16x9
                ? rescale(768, resolution, 'height')
                : rescale(600, resolution, 'height'),
            }}
            resolution={resolution}
          >
            <strong>Session Complete</strong>
            <br />
            Grow Well.
          </Text>
          <List resolution={resolution}>
            <ListItem resolution={resolution}>
              <Text
                size="p"
                style={{ color: 'rgba(255, 255, 255, 0.6)' }}
                resolution={resolution}
              >
                Guide
              </Text>
              <Text size="p" style={{ color: '#fff' }} resolution={resolution}>
                {data.guideName}
              </Text>
            </ListItem>
            <ListItem resolution={resolution}>
              <Text
                size="p"
                style={{ color: 'rgba(255, 255, 255, 0.6)' }}
                resolution={resolution}
              >
                Guest
              </Text>
              <Text size="p" style={{ color: '#fff' }} resolution={resolution}>
                {data.guestName}
              </Text>
            </ListItem>
            <ListItem resolution={resolution}>
              <Text
                size="p"
                style={{ color: 'rgba(255, 255, 255, 0.6)' }}
                resolution={resolution}
              >
                Stay
              </Text>
              <Text size="p" style={{ color: '#fff' }} resolution={resolution}>
                {data.stayStart} – {data.stayEnd}
              </Text>
            </ListItem>
          </List>
        </TopAlign>
      </Sidebar>
      {showExitModal && (
        <Modal>
          <>{resolution.is16x9 && <br />}Advance to exit</>
        </Modal>
      )}
      <VideoBackgroundContainer
        autoPlay
        loop
        muted
        visible
        width={resolution.width}
        height={resolution.height}
      >
        <source src={assets?.outroScr} />
      </VideoBackgroundContainer>
    </div>
  );
};

export default ThankYouWall;
