import React, { useEffect, useRef, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useIdle, useKey } from 'react-use';
import posed, { PoseGroup } from 'react-pose';
import Auth from './components/Auth';
import Modal from './components/Modal';
import { ConsultationsWall } from './components/Wall';
import { SUPPORTED_RESOLUTIONS } from './components/constants';
import { filterActivityData, formatWallData } from './helpers/formatter';
import WallContext from './contexts/WallContext';
import ThankYouWall from './components/Wall/ThankYouWall';
import { AssetContextProvider } from './contexts/AssetContext';
import ResolutionContext from './contexts/ResolutionContext';
import { ScreenResolution } from './types/resolution.type';
import useWindowDimensions from './hooks/useWindowDimensions';
import { UpcomingAppointment } from './types/upcoming-appointment.type';
import TheinhardtLight from './assets/fonts/Theinhardt-Light.woff2';
import TheinhardtRegular from './assets/fonts/Theinhardt-Regular.woff2';
import TheinhardtMedium from './assets/fonts/Theinhardt-Medium.woff2';

type Props = {
  visible: boolean;
  width: number;
  height: number;
};

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Theinhardt-sensei';
    src: url(${TheinhardtLight}) format('woff2');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Theinhardt-sensei';
    src: url(${TheinhardtRegular}) format('woff2');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Theinhardt-sensei';
    src: url(${TheinhardtMedium}) format('woff2');
    font-weight: 500;
    font-style: normal;
  }

  .theinhardt {
    font-family: 'Theinhardt-sensei', sans-serif;
  }

  * {
    margin: 0;
    padding: 0;
    line-height: 1;
    box-sizing: border-box;
    backface-visibility: hidden;
    font-family: 'Theinhardt-sensei', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    width: 100%;
    height: 100%;
    display: flex;
    font-weight: 300;
    align-items: center;
    justify-content: center;
    background: #222;

  }

  html.zoom {
      zoom: 0.1875; /* TODO: (1/4)^n multiple */
  }

  strong {
    font-weight: 500;
  }

  .logo {
    position: absolute;
    top: 320px;
    left: 280px;
    z-index: 2;
    mask-size: contain;
    object-fit: contain;
    background-size: contain;
  }

  .wall {
    overflow: hidden;
    /* If we work in a fixed 4320x1920 we use zoom: 0.5~ to view the entire thing. */
    position: relative;
    background: rgba(0, 0, 0, 1);
  }

  .wall.in-session {
    cursor: none;
  }

  video {
    object-fit: cover;
  }
`;

const WallContainer = styled.div(({ width, height, visible }: Props) => {
  return `position: absolute;
  opacity: ${visible ? 1 : 0};
  pointer-events: ${visible ? 'auto' : 'none'};
  width: ${width}px;
  height: ${height}px;
`;
});

const Item = posed.div({
  enter: {
    opacity: 1,
    delay: 3500,
    transition: { type: 'tween', duration: 2000, ease: 'easeOut' },
  },
  exit: { opacity: 0 },
});

// Routing logic here
function App() {
  const check16x9 = (width: number, height: number) =>
    Math.round((16 / 9) * 100) / 100 ===
    Math.round((width / height) * 100) / 100
      ? true
      : false;
  const resolutionCounter = useRef(0);
  const [step, setStep] = useState(0);
  const [wallData, setWallData] = useState({});
  const { height, width } = useWindowDimensions();
  const [resolution, setResolution] = useState<ScreenResolution>({
    width: width,
    height: height,
    is16x9: check16x9(width, height),
  });

  const [internetConnectivity, setInternetConnectivity] = useState(true);
  const isIdle = useIdle(60e3 * 60);

  useKey('z', () => {
    if (document.querySelector('input:focus') === null) {
      document?.querySelector('html')?.classList.toggle('zoom');
    }
  }); // Toggles the 8K zoom

  useKey('x', () => {
    if (document.querySelector('input:focus') === null) {
      let index = resolutionCounter.current % SUPPORTED_RESOLUTIONS.length;
      if (SUPPORTED_RESOLUTIONS[index].name === 'current') {
        const {
          innerWidth: currentScreenWidth,
          innerHeight: currentScreenHeight,
        } = window;
        setResolution({
          width: currentScreenWidth,
          height: currentScreenHeight,
          name: SUPPORTED_RESOLUTIONS[index].name,
          is16x9: check16x9(width, height),
        });
      } else {
        setResolution(SUPPORTED_RESOLUTIONS[index]);
      }
      resolutionCounter.current++;
    }
  }); // Toggles between the supported and the current screen resolutions

  useKey(
    't',
    () => {
      if (document.querySelector('input:focus') === null) {
        setStep((s) => Math.max((s + 1) % 4, 1));
      }
    },
    {},
    [step]
  );

  useEffect(() => {
    if (isIdle) {
      setStep(0);
      setWallData({});
    }
  }, [isIdle]);

  useEffect(() => {
    let interval: NodeJS.Timer;
    interval = setInterval(() => {
      setInternetConnectivity(navigator.onLine);
    }, 1000);
    return () => clearInterval(interval);
  }, [internetConnectivity]);

  function handleAuthed() {
    if (step === 0) setStep(1);
  }

  function handleData(bookingParty: UpcomingAppointment) {
    setWallData(filterActivityData(formatWallData(bookingParty)));
  }

  function handleReset() {
    setStep(0);
    setWallData({});
  }

  const getNoContentItem = (step: string) => {
    return (
      <Item key={'no-content-' + step}>
        <></>
      </Item>
    );
  };

  return (
    <ResolutionContext.Provider value={resolution}>
      <GlobalStyles />
      <div
        className={step > 0 ? 'wall in-session' : 'wall'}
        style={{ width: resolution.width, height: resolution.height }}
      >
        <WallContext.Provider value={wallData}>
          <AssetContextProvider>
            <WallContainer
              visible
              width={resolution.width}
              height={resolution.height}
            >
              <PoseGroup key={'posegroup' + step}>
                {step === 0 ? (
                  <Item
                    key="auth-step"
                    style={{ width: '100%', height: '100%', zIndex: 2 }}
                  >
                    <Auth
                      active={step === 0}
                      onDone={handleAuthed}
                      onData={handleData}
                      onReset={handleReset}
                      doReset={isIdle}
                    />
                    {!internetConnectivity && (
                      <Modal>No Internet Connectivity</Modal>
                    )}
                  </Item>
                ) : (
                  getNoContentItem('step0')
                )}
                {step === 1 ? (
                  <Item
                    key="consultations-step"
                    style={{ width: '100%', height: '100%', zIndex: 2 }}
                  >
                    <ConsultationsWall
                      active={step === 1}
                      onEnd={() => setStep(2)}
                      doReset={isIdle}
                    />
                  </Item>
                ) : (
                  getNoContentItem('step1')
                )}
                {step === 2 ? (
                  <Item
                    key="thankyou-step"
                    style={{ width: '100%', height: '100%', zIndex: 2 }}
                  >
                    <ThankYouWall
                      active={step === 2}
                      onPrev={() => setStep(1)}
                      onEnd={() => {
                        setStep(0);
                        setWallData({});
                      }}
                    />
                  </Item>
                ) : (
                  getNoContentItem('step2')
                )}
              </PoseGroup>
            </WallContainer>
          </AssetContextProvider>
        </WallContext.Provider>
      </div>
    </ResolutionContext.Provider>
  );
}

export default App;
