import { logger } from '../../services/logger';

export const NO_ENV_REQUIRED_LOGIN_PERMISSIONS = ['shift.disallow_login'];
let REQUIRED_LOGIN_PERMISSIONS: string[];

if (process.env.REACT_APP_LOGIN_PERMISSIONS) {
  REQUIRED_LOGIN_PERMISSIONS = process.env.REACT_APP_LOGIN_PERMISSIONS.split(
    ','
  );
} else {
  logger.error(new Error('No permissions were defined for the application'));
  REQUIRED_LOGIN_PERMISSIONS = NO_ENV_REQUIRED_LOGIN_PERMISSIONS;
}

export const hasLoginPermissions = (permissions: string[]): boolean => {
  return REQUIRED_LOGIN_PERMISSIONS.every((p) => permissions.indexOf(p) !== -1);
};
