import styled from 'styled-components';
import posed from 'react-pose';
import { ScreenResolution } from '../../types/resolution.type';
import { rescale } from '../ratio.util';

type Props = {
  resolution: ScreenResolution;
};
const WallProgress = styled.div(({ resolution }: Props) => {
  return `
  position: absolute;
  bottom: ${rescale(264, resolution, 'height')}px;
  left: ${rescale(240, resolution, 'width')}px;
  right: ${rescale(240, resolution, 'width')}px;
`;
});

const WallProgressAnim = posed(WallProgress)({
  top: {
    y: ({ resolution }: { resolution: ScreenResolution }) => {
      return rescale(-1970, resolution, 'height');
    },
    opacity: 1,
    transition: {
      type: 'spring',
      damping: 18,
    },
  },
  bottom: {
    y: 0,
    delay: 300,
    opacity: 1,
    transition: {
      type: 'spring',
      damping: 18,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      type: 'spring',
      damping: 18,
    },
  },
});

export default WallProgressAnim;
