import styled from 'styled-components';
import posed from 'react-pose';
import { ScreenResolution } from '../../types/resolution.type';
import { rescale } from '../ratio.util';

const transition = {
  type: 'spring',
  damping: 13,
};
type Props = {
  resolution: ScreenResolution;
};
const List = styled.div(({ resolution }: Props) => {
  return `
  margin-top: ${rescale(120, resolution, 'height')}px;
`;
});

const ListItem = styled.div(({ resolution }: Props) => {
  return `
  margin-bottom: ${rescale(64, resolution, 'height')}px;
  width: 100%;
  display: flex;
  font-size: ${rescale(88, resolution, 'height')}px;

  & > :first-child {
    width: 25%;
  }
`;
});

const ListSeparatorContainer = styled.div(({ resolution }: Props) => {
  return `
  margin: ${rescale(120, resolution, 'width')}px 0;
  width: 100%;
  height: ${rescale(4, resolution, 'height')}px;
  background: rgba(0, 0, 0, 0.2);
`;
});

const ListTitleContainer = styled.div(({ resolution }: Props) => {
  return `
  margin-bottom: ${rescale(120, resolution, 'height')}px;
  color: #fff;
  font-size: ${rescale(72, resolution, 'height')}px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: ${rescale(2.8, resolution, 'width')}px;
`;
});

const ListSeparator = posed(ListSeparatorContainer)({
  visible: {
    opacity: 1,
  },
  before: {
    opacity: 0,
  },
  after: {
    opacity: 0,
  },
});

const ListTitle = posed(ListTitleContainer)({
  visible: {
    opacity: 1,
    x: 0,
    transition,
  },
  before: { opacity: 0, x: -100, transition },
  after: { opacity: 0, x: 100, transition },
});

export { List, ListSeparator, ListItem, ListTitle };
