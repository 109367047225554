import React, { useContext } from 'react';
import styled from 'styled-components';
import { ScreenResolution } from '../../types/resolution.type';
import { rescale } from '../ratio.util';
import ResolutionContext from '../../contexts/ResolutionContext';

const SidebarContainer = styled.div`
  pointer-events: none;
  position: absolute;
  width: 25%;
  height: 100%;
  z-index: 2;
`;

const SidebarLightBackground = styled.div`
  opacity: ${(props: { visible: boolean }) => (props.visible ? 1 : 0)};
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
  transition: 0.4s ease all;
  transition-delay: 0.4s;
  z-index: -1;
`;

type SidebarDarkBackgroundProps = {
  resolution: ScreenResolution;
  visible: boolean;
};
const SidebarDarkBackground = styled.div(
  ({ resolution, visible }: SidebarDarkBackgroundProps) => {
    return `
  opacity: ${visible ? 1 : 0};
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  transition: 0.4s ease all;
  transition-delay: 0.4s;
  backdrop-filter: blur(${rescale(60, resolution, 'width')}px);
  z-index: -1;
`;
  }
);

type Props = {
  dark: boolean;
  children?: React.ReactNode;
};
const Sidebar = function ({ dark, children }: Props) {
  const resolution = useContext(ResolutionContext);
  return (
    <SidebarContainer>
      {children}
      <SidebarDarkBackground visible={dark} resolution={resolution} />
      <SidebarLightBackground visible={!dark} />
    </SidebarContainer>
  );
};

export default Sidebar;
