import styled from 'styled-components';
import React, { useContext } from 'react';
import { PROPERTIES } from '../constants';
import { ScreenResolution } from '../../types/resolution.type';
import { rescale } from '../ratio.util';
import ResolutionContext from '../../contexts/ResolutionContext';

type ResolutionProps = {
  resolution: ScreenResolution;
};
const DemoSessionContainer = styled.div(({ resolution }: ResolutionProps) => {
  return `
  position: absolute;
  top: ${rescale(280, resolution, 'height')}px;
  right: ${rescale(200, resolution, 'width')}px;
  z-index: 5;
`;
});

const DemoSession = styled.a(({ resolution }: ResolutionProps) => {
  return `
  display: block;
  font-size: ${rescale(88, resolution, 'height')}px;
  padding: ${rescale(40, resolution, 'width')}px;
  cursor: pointer;
  color: #888;
  text-decoration: none;
`;
});

type Props = {
  onDemoSession: (property: number) => void;
};
export const DemoSessions = ({ onDemoSession }: Props) => {
  const resolution = useContext(ResolutionContext);
  return (
    <DemoSessionContainer resolution={resolution}>
      <DemoSession
        onClick={() => onDemoSession(PROPERTIES.LANAI)}
        resolution={resolution}
      >
        Lanai Demo Session
      </DemoSession>
      <br />
      <DemoSession
        onClick={() => onDemoSession(PROPERTIES.PORCUPINE_CREEK)}
        resolution={resolution}
      >
        Porcupine Creek Demo Session
      </DemoSession>
    </DemoSessionContainer>
  );
};
