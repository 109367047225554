import styled from 'styled-components';
import { ScreenResolution } from '../../types/resolution.type';
import { rescale } from '../ratio.util';

type Props = {
  resolution: ScreenResolution;
  visible?: boolean;
};
export const PreloadMessage = styled.div(({ resolution, visible }: Props) => {
  return `
  position: absolute;
  opacity: ${visible ? 1 : 0};
  left: ${rescale(240, resolution, 'width')}px;
  bottom: ${rescale(280, resolution, 'height')}px;
  color: rgba(255, 255, 255, 0.15);
  font-size: ${rescale(88, resolution, 'height')}px;
  z-index: 20;
  pointer-events: none;
  transition: 1s ease all;
  transform: translateX(${visible ? 0 : rescale(-70, resolution, 'width')}px);
`;
});
