import React, { useContext } from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import { DateTime } from 'luxon';
import { Text } from '../Text';
import WallContext from '../../contexts/WallContext';
import { useKey } from 'react-use';
import { CurrentPose } from 'react-pose/lib/components/PoseElement/types';
import { Day } from '../../types/wall-data.type';
import ResolutionContext from '../../contexts/ResolutionContext';
import { ScreenResolution } from '../../types/resolution.type';
import { rescale } from '../ratio.util';

type DayContainerProps = {
  resolution: ScreenResolution;
  index: number;
};

const DayContainer = styled.div(({ index, resolution }: DayContainerProps) => {
  index += 1;
  return `
    position: absolute;
    top: ${index % 2 === 0 ? '0px' : '55%'};
    left: ${
      Math.floor(index / 2) *
      (rescale(1852, resolution, 'width') + rescale(312, resolution, 'width'))
    }px;
    display: flex;
    flex-direction: row;
  `;
});

type DayBadgeContainerProps = {
  resolution: ScreenResolution;
  i: number;
};

const DayBadgeContainer = styled.div(
  ({ resolution, i }: DayBadgeContainerProps) => {
    return `
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: ${rescale(280, resolution, 'width')}px;
  height: ${rescale(280, resolution, 'width')}px;
  border-radius: ${rescale(280, resolution, 'width')}px;
  background: #fff;
`;
  }
);

type DayBadgeNameProps = {
  resolution: ScreenResolution;
};
const DayBadgeName = styled.div(({ resolution }: DayBadgeNameProps) => {
  return `
  margin-top: ${rescale(-10, resolution, 'height')}px;
  margin-bottom: ${rescale(-5, resolution, 'height')}px;
  color: #686868;
  font-size: ${rescale(44, resolution, 'height')}px;
`;
});

type DayBadgeNumberProps = {
  resolution: ScreenResolution;
};

const DayBadgeNumber = styled.div(({ resolution }: DayBadgeNumberProps) => {
  return `
  color: #343434;
  font-size: ${rescale(122, resolution, 'height')}px;
`;
});

type DayListContainerProps = {
  resolution: ScreenResolution;
};

const DayListContainer = styled.div(({ resolution }: DayListContainerProps) => {
  return `
  padding-left: calc(${rescale(280, resolution, 'width')}px + ${rescale(
    128,
    resolution,
    'width'
  )}px);
`;
});

type DayListItemProps = {
  resolution: ScreenResolution;
};

const DayListItem = styled.div(({ resolution }: DayListItemProps) => {
  return `
  margin-bottom: ${rescale(50, resolution, 'height')}px;
  display: flex;
  width: ${rescale(1800, resolution, 'width')}px;
`;
});

const DayBadge = posed(DayBadgeContainer)({
  visible: {
    delay: 200,
    opacity: 1,
    scale: 1,
    transition: ({ i }: { i: number }) => ({
      type: 'spring',
      delay: 100 + i * 50,
      damping: 15,
    }),
  },
  hidden: {
    delay: 100,
    opacity: 0,
    scale: 0,
    transition: {
      type: 'tween',
      duration: 200,
    },
  },
  props: { i: 0 },
});

const DayList = posed(DayListContainer)({
  visible: {
    opacity: 1,
    x: 0,
    delay: 500,
    transition: {
      type: 'tween',
      duration: 300,
    },
  },
  hidden: {
    opacity: 0,
    delay: 0,
    transition: {
      type: 'tween',
      duration: 300,
    },
  },
});

type Props = {
  day: Day;
  highlight: string[];
  index: number;
  pose: CurrentPose;
};

function CalendarDay({ day, highlight = [], index, pose }: Props) {
  const resolution = useContext(ResolutionContext);
  return (
    <DayContainer index={index} resolution={resolution}>
      <DayBadge pose={pose} i={index} resolution={resolution}>
        <DayBadgeName resolution={resolution}>
          {day.date.split(' ')[0]}
        </DayBadgeName>
        <DayBadgeNumber resolution={resolution}>
          {day.date.split(' ')[1]}
        </DayBadgeNumber>
      </DayBadge>
      <DayList pose={pose} resolution={resolution}>
        {day.events.map((appointment, index) => {
          const event = appointment.activity?.name;
          const time = appointment.startDate
            ? DateTime.fromISO(appointment.startDate)
                .setLocale('en')
                .toFormat('h:mm a')
            : '';
          const isArrivalDay = appointment?.activity?.category?.name === 'ArrivalDay';
          // TODO: Depending on what highlight looks like, we change the line below
          const hl =
            event &&
            (highlight.includes(event) ||
              highlight.includes('all') ||
              (appointment.activity?.category &&
                highlight.includes(appointment.activity.category.name)));
          const timeColor =
            event &&
            (highlight.includes(event) ||
              (appointment.activity?.category &&
                highlight.includes(appointment.activity.category.name)))
              ? '#fff'
              : 'rgba(255, 255, 255, 0.3)';
          const eventColor = hl ? '#fff' : 'rgba(255, 255, 255, 0.3)';

          return (
            <DayListItem key={`DayListItem-${index}`} resolution={resolution}>
              {!isArrivalDay && (
                <Text
                  resolution={resolution}
                  size="p"
                  style={{
                    width: resolution.name === '8k' ? '20%' : '25%',
                    marginRight: rescale(128, resolution, 'width'),
                    color: timeColor,
                    transition: '0.3s ease all',
                  }}
                >
                  {time.toLowerCase()}
                </Text>
              )}
              <Text
                resolution={resolution}
                size="p"
                style={{
                  color: eventColor,
                  width: '62%',
                  fontWeight: hl && !highlight.includes('all') ? 500 : 300,
                  transition: '0.2s ease color',
                }}
              >
                {event}
              </Text>
            </DayListItem>
          );
        })}
      </DayList>
    </DayContainer>
  );
}

type CalendarContainerProps = {
  resolution: ScreenResolution;
};
const CalendarContainer = styled.div(
  ({ resolution }: CalendarContainerProps) => {
    return `
  position: absolute;
  left: ${rescale(240, resolution, 'width')}px;
  top: ${rescale(320, resolution, 'height')}px;
  right: ${rescale(240, resolution, 'width')}px;
  bottom: ${rescale(240, resolution, 'height')}px;
`;
  }
);

type CalendarProps = {
  highlight: string[];
  pose: CurrentPose;
  children?: React.ReactNode;
};
// @ts-ignore
const Calendar = React.forwardRef<HTMLDivElement, CalendarProps>(
  (props, ref) => {
    const data = useContext(WallContext);
    const [page, setPage] = React.useState(0);
    const maxSlotsOnScreen = 7;
    const days = data.days;
    const resolution = useContext(ResolutionContext);

    useKey(
      'ArrowDown',
      () => {
        if (
          days &&
          props.pose === 'visible' &&
          (page + 1) * maxSlotsOnScreen < days.length
        ) {
          setPage((prevValue) => prevValue + 1);
        }
      },
      {},
      [props, page, setPage]
    );
    useKey(
      'ArrowUp',
      () => {
        if (props.pose === 'visible' && page > 0) {
          setPage((prevValue) => prevValue - 1);
        }
      },
      {},
      [props, page, setPage]
    );
    return (
      <CalendarContainer ref={ref} {...props} resolution={resolution}>
        {days
          ?.slice(page * maxSlotsOnScreen, (page + 1) * maxSlotsOnScreen)
          .map((day, index) => {
            return (
              <CalendarDay
                pose={props.pose}
                day={day}
                index={index}
                highlight={props.highlight}
                key={`calendar-day-${index}`}
              />
            );
          })}
      </CalendarContainer>
    );
  }
);

export default Calendar;
