import { DateTime } from 'luxon';
import { AppointmentType } from '../types/appointment.type';
import { Intention } from '../types/intention.type';
import { WallData } from '../types/wall-data.type';
import { UpcomingAppointment } from '../types/upcoming-appointment.type';
// Just helps format the graphql data

/*
 * Group items from an array together by some criteria or value.
 */

const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) previous[group] = [];
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);

// TODO: These should pull dynamically from schema API
const INTENTION_TRANSLATIONS: Record<Intention, string> = {
  relaxation: 'De-stress/relaxation',
  special_occasion: 'Celebrate birthday, anniversary, special occasion',
  time_with_loved_ones: 'Spending time with loved ones',
  new_life_stage: 'Entering a new life stage',
  improve_health: 'Improve health and wellbeing',
  personal_reflection: 'Personal reflection and discovery',
  not_sharing: "Haven't decided yet",
};

function formatIntentions(intentions?: Intention[] | null) {
  if (!intentions) {
    return;
  }

  return intentions.map((intention: Intention) => {
    if (INTENTION_TRANSLATIONS[intention]) {
      return INTENTION_TRANSLATIONS[intention];
    } else {
      // Fallback if no translation string is present
      return humanize(intention);
    }
  });
}

function formatMealTimes(meals: string[]) {
  return meals.map((meal) => humanize(meal));
}

function formatPhoneNumber(str: string): string | null {
  let cleaned = ('' + str).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

function humanize(meal: string) {
  // Fallback if no translation string is present
  function capitalize(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  let fragments = meal ? meal.split('_') : [];
  fragments = fragments.map((f) => capitalize(f));
  return fragments.join(' ');
}

export const defaultData = {
  guideName: '',
  guidePhone: '',
  guestName: '',
  stayStart: '',
  stayEnd: '',
  wake: '',
  meals: [],
  intentions: [],
  days: [],
  assessment: {
    start: null,
    location: null,
  },
};

export function formatWallData(
  appointment: UpcomingAppointment,
): Partial<WallData> {
  if (Object.keys(appointment.bookingParty).length === 0) return defaultData;
  const bookingParty = appointment.bookingParty;

  if (!bookingParty) return {};
  return {
    guestName: `${bookingParty.member?.user.firstName} ${bookingParty.member?.user.lastName}`,
    guideName: `${bookingParty.guideForBooking?.user.firstName} ${bookingParty.guideForBooking?.user.lastName}`,
    guidePhone: formatPhoneNumber(
      bookingParty.guideForBooking?.user.cellPhone || '',
    ),
    // TODO: Is this formatting OK?
    stayStart: bookingParty.booking?.startDate
      ? DateTime.fromISO(bookingParty.booking?.startDate)
          .setLocale('en')
          .toFormat('LLL dd')
      : '',
    stayEnd: bookingParty.booking?.endDate
      ? DateTime.fromISO(bookingParty.booking.endDate)
          .setLocale('en')
          .toFormat('LLL dd')
      : '',
    assessment: {
      start: appointment?.startDate
        ? DateTime.fromISO(appointment.startDate).toFormat('h:mm a')
        : null,
      location:
        appointment && appointment.location && appointment.location.name
          ? appointment.location.name
          : null,
    },
    wake: bookingParty?.prearrival?.preferredWakeTime
      ? bookingParty?.prearrival?.preferredWakeTime
      : null,
    meals:
      bookingParty?.prearrival?.mealTimes &&
      bookingParty.prearrival.mealTimes.length > 0
        ? formatMealTimes(bookingParty?.prearrival?.mealTimes)
        : [''],
    intentions:
      bookingParty?.prearrival?.intentions &&
      bookingParty.prearrival.intentions.length > 0
        ? formatIntentions(bookingParty?.prearrival?.intentions)
        : null,
    scheduleType: bookingParty.prearrival?.scheduleType
      ? bookingParty.prearrival?.scheduleType
      : null,

    days: Object.entries(
      groupBy(
        bookingParty.appointments || [],
        (appointment: Partial<AppointmentType>) =>
          appointment.startDate
            ? DateTime.fromISO(appointment.startDate).toFormat('EEE d')
            : ''
      )
    ).map(([key, value]) => ({
      date: key,
      events: value,
    })),

    logInTimestamp: new Date(),
  };
}

export function filterActivityData(activityData: Partial<WallData>) {
  const filteredData = JSON.parse(JSON.stringify(activityData)) as WallData;
  (filteredData.days || []).forEach((day) => {
    if (day.events.length > 7) {
      day.events = day.events.filter((e) => !e.activity?.isTravel);
    }
    if (day.events.length > 7) {
      day.events = day.events.splice(0, 7);
    }
  });
  return filteredData;
}
