import styled from 'styled-components';
import posed from 'react-pose';
import { ScreenResolution } from '../../types/resolution.type';

type Props = {
  resolution: ScreenResolution;
  src?: string;
};
const SingleImage = styled.div(({ resolution, src }: Props) => {
  const originalBackgroundSize = resolution.width - resolution.width * 0.25;
  const backgroundSizeWhenAspectRatio_16_9 =
    resolution.width - resolution.width * 0.21;
  return `
  position: absolute;
  left: 100%;
  right: 0;
  width: calc(${resolution.width}px - 100%);
  height: ${resolution.height}px;
  object-fit: cover;
  will-change: width;
  background-image: url(${src});
  background-size: ${
    resolution.name === '8k'
      ? originalBackgroundSize
      : backgroundSizeWhenAspectRatio_16_9
  }px;
`;
});

const transition = {
  duration: 800,
  ease: [0.77, 0, 0.175, 1],
};

const SingleImageAnim = posed(SingleImage)({
  visible: {
    delay: 200,
    width: ({ resolution }: { resolution: ScreenResolution }) => {
      return resolution.width - resolution.width * 0.25;
    },
    transition,
  },
  before: {
    width: 0,
    transition,
  },
  after: {
    width: 0,
    transition,
  },
});

export default SingleImageAnim;
