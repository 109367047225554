import React from 'react';
import posed from 'react-pose';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import { rescale } from '../ratio.util';
import { ScreenResolution } from '../../types/resolution.type';

type SidebarContainerProps = {
  visible: boolean;
  children?: React.ReactNode;
};
const SidebarContainer = styled.div(({ visible }: SidebarContainerProps) => {
  return `
    pointer-events: ${visible ? 'auto' : 'none'};
    position: absolute;
    width: 25%;
    height: 100%;
    z-index: 1;
  `;
});
type SidebarBackgroundProps = {
  opacity: number;
  theme: {
    SidebarBackground: string;
  };
};
const SidebarBackground = styled.div(
  ({ opacity, theme }: SidebarBackgroundProps) => {
    return `
    opacity: ${opacity};
    position: relative;
    width: 100%;
    height: 100%;
    background-color: ${theme.SidebarBackground};
    transition: 0.4s ease all;
    will-change: background-color;
  `;
  }
);

SidebarBackground.defaultProps = {
  theme: {
    SidebarBackground: '#000',
  },
};

type Props = {
  visible: boolean;
  expanded: boolean | number | undefined;
  opacity: number;
  children: React.ReactNode;
  resolution: ScreenResolution;
};

function Sidebar(props: Props) {
  const { visible = true, expanded, opacity = 0.25, children } = props;

  const [spring, set] = useSpring(
    () =>
      ({
        position: 'relative',
        transformOrigin: 'left center',
        transform: `scale3d(${expanded ? 1 : 0.25}, 1, 1)`,
        willChange: 'transform',
        zIndex: -1,
        config: {
          mass: 1,
          tension: 80,
          friction: 15,
          precision: 0.001,
        },
      } as React.CSSProperties)
  );

  set({
    transform: `scale3d(${expanded === 0 ? 0.2 : expanded ? 1 : 0.25}, 1, 1)`,
  });

  return (
    <SidebarContainer visible={visible}>
      {children}
      <animated.div
        style={{
          ...spring,
          width: props.resolution.width,
          height: props.resolution.height,
        }}
      >
        <SidebarBackground opacity={opacity} />
      </animated.div>
    </SidebarContainer>
  );
}

type BottomAlignContainerProps = {
  resolution: ScreenResolution;
};
const BottomAlignContainer = styled.div(
  ({ resolution }: BottomAlignContainerProps) => {
    return `
  position: absolute;
  left: ${rescale(240, resolution, 'width')}px;
  right: ${rescale(240, resolution, 'width')}px;
  bottom: ${rescale(600, resolution, 'height')}px;
  z-index: 1;
`;
  }
);

const TopAlignContainer = styled.div(
  ({ resolution }: BottomAlignContainerProps) => {
    return `
  position: absolute;
  top: ${rescale(800, resolution, 'height')}px;
  left: ${rescale(240, resolution, 'width')}px;
  right: ${rescale(240, resolution, 'width')}px;
  z-index: 2;
`;
  }
);

const TopAlign = posed(TopAlignContainer)({
  visible: { opacity: 1, staggerChildren: 20, delayChildren: 400 },
  hidden: { opacity: 0 },
});

const BottomAlign = posed(BottomAlignContainer)({
  visible: { opacity: 1, staggerChildren: 40, delayChildren: 400 },
  hidden: { opacity: 0 },
});

export default Sidebar;
export { TopAlign, BottomAlign };
