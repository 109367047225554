import styled from 'styled-components';
import posed from 'react-pose';
import { ScreenResolution } from '../../types/resolution.type';
import { rescale } from '../ratio.util';

const SmallGateContainer = styled.div(({ width, height }: ScreenResolution) => {
  return `position: absolute;
  top: 0;
  left: 100%;
  width: ${width}px;
  height: ${height}px;
  display: flex;
`;
});

const SmallGateItem = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
type SmallGateTitleProps = {
  resolution: ScreenResolution;
};
const SmallGateTitle = styled.div(({ resolution }: SmallGateTitleProps) => {
  return `
  position: absolute;
  padding-left: ${rescale(240, resolution, 'width')}px;
  top: ${rescale(1020, resolution, 'height')}px;
  color: #fff;
  font-size: ${rescale(200, resolution, 'height')}px;
`;
});

const SmallGateImage = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  background-image: url(${({ src }: { src: string }) => (src ? src : '')});
  background-size: cover;
  background-position: left center;
  border: 0;
  outline: 0;
  will-change: width;
`;

const transition = {
  duration: 800,
  ease: [0.77, 0, 0.175, 1],
};

const SmallGateContainerAnim = posed(SmallGateContainer)({
  visible: {
    delayChildren: 400,
    staggerChildren: 500,
  },
  before: {},
  after: {},
});

const SmallGateImageAnim = posed(SmallGateImage)({
  visible: {
    width: ({ width }: { width: number }) => {
      return width / 4;
    },
    transition,
  },
  before: {
    width: 0,
    transition,
  },
  after: {
    width: 0,
    transition,
  },
});

const SmallGateTitleAnim = posed(SmallGateTitle)({
  visible: {
    opacity: 1,
    transition,
  },
  before: {
    opacity: 0,
    transition,
  },
  after: {
    opacity: 0,
    transition,
  },
});

export {
  SmallGateContainerAnim as SmallGateContainer,
  SmallGateImageAnim as SmallGateImage,
  SmallGateItem,
  SmallGateTitleAnim as SmallGateTitle,
};
