import React, { useContext, useEffect, useState } from 'react';
import { useKey } from 'react-use';

import Logo from '../Logo';
import Dots from '../Dots';
import Sidebar from '../Sidebar';
import Stopwatch from '../Stopwatch';
import Title from '../Title'; // TODO: Rename this...
import Calendar from '../Calendar';
import WallProgress from './WallProgress';
import VideoBackground from '../VideoBackground';

import WallContext from '../../contexts/WallContext';

import {
  AssessmentsMerged,
  Intro,
  Movement,
  MyStay,
  Nourishment,
  PAQ,
  Rest,
  StayMap,
  WhatIsGuide,
  WhatIsSensei,
} from '../Slides';
import { Slide } from '../../types/slide.type';
import ResolutionContext from '../../contexts/ResolutionContext';

function getDisplayName(component: any): string {
  return component.displayName || component.name || 'Component';
}

type Props = {
  onEnd: () => void;
  active: boolean;
  doReset: boolean;
};

function ConsultationsWall({ onEnd, active, doReset }: Props) {
  const [progress, setProgress] = useState(0); // Index starts at 0
  const data = useContext(WallContext);
  const resolution = useContext(ResolutionContext);

  const slides: Partial<Slide>[] = [
    // Assessment with...
    {
      chapter: '',
      component: Intro,
      type: 0,
    },
    // PAQ,
    {
      chapter: '',
      component: PAQ,
      extended: false,
      type: 4,
      opacity: 0.5,
    },
    // What is a guide?
    {
      chapter: 'Overview',
      component: WhatIsGuide,
      expanded: true,
      type: 0,
      opacity: 0.25,
    },
    // What is Sensei
    {
      chapter: 'Overview',
      component: WhatIsSensei,
      expanded: true,
      type: 0,
      opacity: 0.25,
    },
    {
      chapter: '',
      component: AssessmentsMerged,
      expanded: false,
      type: 1,
    },
    // // Blood pressure
    // {
    //   chapter: '',
    //   component: BloodPressure,
    //   expanded: false,
    //   type: 0,
    // },
    // // Body composition
    // {
    //   chapter: '',
    //   component: BodyComposition,
    //   expanded: false,
    //   type: 1,
    // },

    // // 3D body scanner
    // {
    //   chapter: '',
    //   component: BodyScanner,
    //   expanded: false,
    //   type: 2,
    // },
    // Ready for VR
    // {
    //   chapter: '',
    //   component: VR,
    //   expanded: false,
    //   type: 3,
    //   opacity: 0.6,
    // },
    // // Meditation
    // {
    //   chapter: '',
    //   component: Meditation,
    //   expanded: true,
    //   type: 3,
    //   opacity: 0.8,
    // },
    // // Meditation complete
    // {
    //   chapter: '',
    //   component: MeditationComplete,
    //   expanded: false,
    //   type: 4,
    //   opacity: 0.5,
    // },
    // My stay
    {
      chapter: 'My Stay',
      component: MyStay,
      expanded: true,
      type: 2,
      opacity: 0.5,
      progressPosition: 'top',
    },
    // Movement
    {
      chapter: '',
      component: Movement,
      expanded: true,
      type: 2,
      opacity: 0.5,
      progressPosition: 'top',
    },
    // Nourishment
    {
      chapter: '',
      component: Nourishment,
      expanded: true,
      type: 2,
      opacity: 0.5,
      progressPosition: 'top',
    },
    // Rest
    {
      chapter: '',
      component: Rest,
      expanded: true,
      type: 2,
      opacity: 0.5,
      progressPosition: 'top',
    },
    // My stay
    {
      chapter: 'My Stay',
      component: MyStay,
      expanded: true,
      type: 2,
      opacity: 0.5,
      progressPosition: 'top',
    },
    // Lanai map
    {
      chapter: 'My Stay',
      component: StayMap,
      expanded: true,
      type: 2,
      opacity: 0.5,
      hideTitle: true,
    },
  ];
  const totalSlides = slides.length;

  useKey(
    'ArrowLeft',
    () => {
      if (active) {
        setProgress((p) => Math.max(--p, 0));
      }
    },
    {},
    [active]
  );

  useKey(
    'ArrowRight',
    () => {
      if (active) {
        setProgress((p) => {
          if (p === totalSlides - 1) {
            if (onEnd) onEnd();
          }
          return Math.min(++p, totalSlides - 1);
        });
      }
    },
    {},
    [active]
  );

  const slide = slides[progress];
  const calendarHighlightMap: { [index: number]: string[] } = {
    5: ['all'],
    6: ['Move'],
    7: ['Nourish'],
    8: ['Rest'],
    9: ['all'],
  };

  const calendarNames = [
    getDisplayName(Movement),
    getDisplayName(Nourishment),
    getDisplayName(Rest),
    getDisplayName(MyStay),
  ];

  useEffect(() => {
    if (doReset) setProgress(0);
  }, [doReset]);

  return (
    <>
      <Logo title={slide.chapter} />
      <Title
        pose={
          slide.hideTitle
            ? 'hidden'
            : slide.progressPosition !== 'top'
            ? 'visible'
            : 'hidden'
        }
      />
      <Sidebar
        resolution={resolution}
        visible={true}
        expanded={!active && progress === 0 ? 0 : slide.expanded}
        opacity={
          !active && progress === 0 ? 0 : slide.opacity ? slide.opacity : 0.25
        }
      >
        <>
          {slides.map((s, index) => (
            // @ts-ignore
            <s.component
              id={`consultation-slide-${index}`}
              key={`consultation-slide-${index}`}
              pose={
                !active && progress === 0
                  ? 'before'
                  : progress === index
                  ? 'visible'
                  : progress < index
                  ? 'before'
                  : 'after'
              }
            />
          ))}
          <Calendar
            highlight={calendarHighlightMap[progress]}
            pose={
              calendarNames.includes(getDisplayName(slide.component))
                ? 'visible'
                : 'hidden'
            }
          />
        </>
        <WallProgress
          resolution={resolution}
          pose={
            !active
              ? 'hidden'
              : slide.progressPosition
              ? slide.progressPosition
              : 'bottom'
          }
        >
          <Dots progress={progress} total={totalSlides} />
          <Stopwatch startFrom={data.logInTimestamp} />
        </WallProgress>
      </Sidebar>
      <VideoBackground type={slide.type} />
    </>
  );
}

export default ConsultationsWall;
