import React, { useContext } from 'react';
import styled from 'styled-components';
import { ScreenResolution } from '../../types/resolution.type';
import { rescale } from '../ratio.util';
import ResolutionContext from '../../contexts/ResolutionContext';

type DotContainerStyleProps = { position: string };
const DotsContainer = styled.div(({ position }: DotContainerStyleProps) => {
  return `
    display: flex;
    z-index: 1;
    overflow: hidden;
  `;
});
type DotStyleProps = {
  resolution: ScreenResolution;
  active: boolean;
  index: number;
  theme: {
    ProgressDot: string;
    ProgressBar: string;
  };
};
const Dot = styled.div(
  ({ active, index, theme, resolution }: DotStyleProps) => {
    return `
    margin-right: ${rescale(48, resolution, 'width')}px;
    width: ${rescale(32, resolution, 'width')}px;
    height: ${rescale(32, resolution, 'width')}px;
    border-radius: ${rescale(32, resolution, 'width')}px;
    border: ${
      active
        ? `${rescale(4, resolution, 'width')}px solid transparent`
        : `${rescale(4, resolution, 'width')}px solid ${theme.ProgressDot}`
    };
    background: ${active ? theme.ProgressBar : 'transparent'};
    transform: translateX(${
      active ? rescale(-32, resolution, 'width') : 0
    }px) scale(${active ? 0.9 : 1});
    transition: 0.4s all cubic-bezier(0.77, 0, 0.175, 1);
  `;
  }
);
type BarStyleProps = {
  resolution: ScreenResolution;
  progress: number;
  total: number;
  theme: {
    ProgressBar: string;
  };
};
const Bar = styled.div(
  ({ progress, total, theme, resolution }: BarStyleProps) => {
    return `
    position: absolute;
    top: 0;
    width: ${
      ((progress + 1) / total) * rescale(80, resolution, 'width') * total -
      rescale(48, resolution, 'width')
    }px;
    height: ${rescale(32, resolution, 'height')}px;
    border-radius: ${rescale(32, resolution, 'width')}px;
    background: ${theme.ProgressBar};
    transition: 0.4s width cubic-bezier(0.77, 0, 0.175, 1), 0.4s background ease;
    z-index: 2;
  `;
  }
);

Bar.defaultProps = {
  theme: {
    ProgressBar: '#fff',
  },
};

Dot.defaultProps = {
  theme: {
    ProgressDot: 'rgba(255, 255, 255, 0.7)',
  },
};

type Props = {
  progress?: number;
  total?: number;
  position?: string;
};

function Dots(props: Props) {
  const { progress = 3, total = 10, position = 'bottom' } = props;
  const resolution = useContext(ResolutionContext);
  return (
    <DotsContainer position={position}>
      <Bar progress={progress} total={total} resolution={resolution} />
      {Array.from(Array(total).keys()).map((i) => {
        return (
          <Dot
            key={`dot-${i}`}
            index={i}
            active={progress >= i}
            resolution={resolution}
          />
        );
      })}
    </DotsContainer>
  );
}

export default Dots;
