import posed from 'react-pose';
import styled from 'styled-components';
import { ScreenResolution } from '../types/resolution.type';
import { rescale } from './ratio.util';

const SIZES = {
  h1: {
    color: '#fff',
    fontSize: 200,
    lineHeight: 248,
  },
  h2: {
    color: '#fff',
    fontSize: 160,
    lineHeight: 208,
  },
  p: {
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: 88,
    lineHeight: 96 * 1.4,
  },
};

type TextProps = {
  color?: string;
  resolution: ScreenResolution;
  size?: 'h1' | 'h2' | 'p';
};
export const Text = styled.div(
  ({ color, size = 'h1', resolution }: TextProps) => {
    const base = `
    color: ${color ? color : SIZES[size].color};
    font-size: ${rescale(SIZES[size].fontSize, resolution, 'height')}px;
    line-height: ${rescale(SIZES[size].lineHeight, resolution, 'height')}px;
    transition-origin: left center;
  `;
    let extra;

    return `${base} ${extra}`;
  }
);

const transition = {
  type: 'spring',
  damping: 13,
};
const TextAnim = posed(Text)({
  visible: {
    opacity: 1,
    x: 0,
    transition,
  },
  before: { opacity: 0, x: -100, transition },
  after: { opacity: 0, x: 100, transition },
});

export default TextAnim;
