import React, { useContext } from 'react';
import styled from 'styled-components';
import { ScreenResolution } from '../../types/resolution.type';
import { rescale } from '../ratio.util';
import ResolutionContext from '../../contexts/ResolutionContext';

const DotsContainer = styled.div(({ position }: { position: string }) => {
  const baseStyle = `
    display: flex;
    z-index: 1;
    overflow: hidden;
  `;
  return baseStyle;
});

type DotStyleProps = {
  resolution: ScreenResolution;
  active: boolean;
  done: boolean;
  expanded: boolean;
  theme: {
    ProgressDot: string;
    ProgressBar: string;
  };
  day: number;
  dayProgress: number;
};
const Dot = styled.div(
  ({ active, done, expanded, theme, resolution }: DotStyleProps) => {
    if (expanded) {
      return `
      margin-right: ${rescale(48, resolution, 'width')}px;
      width: ${rescale(160, resolution, 'width')}px;
      height: ${rescale(32, resolution, 'height')}px;
      border-radius: ${rescale(32, resolution, 'width')}px;
      border: ${rescale(4, resolution, 'width')}px solid ${theme.ProgressDot};
      transition: 0.4s all cubic-bezier(0.77, 0, 0.175, 1);
    `;
    }
    return `
    margin-right: ${
      done
        ? rescale(48, resolution, 'width') + rescale(80, resolution, 'width')
        : rescale(48, resolution, 'width')
    }px;
    width: ${rescale(32, resolution, 'width')}px;
    height: ${rescale(32, resolution, 'height')}px;
    border-radius: ${rescale(32, resolution, 'width')}px;
    border: ${
      active
        ? `${rescale(4, resolution, 'width')}px solid transparent`
        : `${rescale(4, resolution, 'width')}px solid ${theme.ProgressDot}`
    };
    background: ${active ? theme.ProgressBar : 'transparent'};
    // transform: translateX(${
      active ? rescale(-32, resolution, 'width') : 0
    }px) scale(${active ? 0.9 : 1});
    transition: 0.4s all cubic-bezier(0.77, 0, 0.175, 1);
  `;
  }
);

type BarStyleProps = {
  resolution: ScreenResolution;
  progress: number;
  total: number;
  theme: {
    ProgressDot: string;
    ProgressBar: string;
  };
};
const Bar = styled.div(
  ({ progress, total, theme, resolution }: BarStyleProps) => {
    return `
    position: absolute;
    top: 0;
    width: ${Math.max(
      (progress / total) * rescale(160, resolution, 'width') * total -
        rescale(47, resolution, 'width'),
      0
    )}px;
    height: 32px;
    border-radius: ${rescale(32, resolution, 'width')}px;
    background: ${theme.ProgressBar};
    transition: 0.4s width cubic-bezier(0.175, 0.885, 0.32, 1.275), 0.4s background ease;
    transition-delay: 0.3s;
    z-index: 2;
  `;
  }
);

type InnerBarStyleProps = {
  resolution: ScreenResolution;
  progress: number;
  theme: {
    ProgressBar: string;
  };
  active: boolean;
  visible: boolean;
  done: boolean;
};
const InnerBar = styled.div(
  ({
    progress,
    active,
    visible,
    done,
    theme,
    resolution,
  }: InnerBarStyleProps) => {
    return `
    position: absolute;
    top: 0;
    // opacity: ${visible ? 1 : 0};
    width: ${
      !active ? 0 : done ? 0 : progress * rescale(160, resolution, 'width')
    }px;
    height: ${rescale(32, resolution, 'height')}px;
    border-top-left-radius: ${rescale(32, resolution, 'width')}px;
    border-bottom-left-radius: ${rescale(32, resolution, 'width')}px;
    background: ${theme.ProgressBar};
    transition: 0.4s width cubic-bezier(0.77, 0, 0.175, 1), 0.4s background ease;
    z-index: 2;
  `;
  }
);

Bar.defaultProps = {
  theme: {
    ProgressBar: '#fff',
  },
};

Dot.defaultProps = {
  theme: {
    ProgressDot: 'rgba(255, 255, 255, 0.7)',
  },
};
type Props = {
  progress: number;
  day: number;
  dayProgress: number;
  expanded: boolean;
  total: number;
  position: string;
};

function DotsMulti(props: Props) {
  const {
    progress = 0,
    day = 0,
    dayProgress = 0,
    expanded = false,
    total = 10,
    position = 'bottom',
  } = props;

  const resolution = useContext(ResolutionContext);

  return (
    <DotsContainer position={position}>
      <Bar progress={progress} total={total} resolution={resolution} />
      {Array.from(Array(total).keys()).map((i) => {
        return (
          <Dot
            resolution={resolution}
            key={`dot-${i}`}
            day={day}
            dayProgress={day === i ? dayProgress : 0}
            expanded={day === i && expanded}
            active={progress >= i}
            done={day > i}
          >
            <InnerBar
              resolution={resolution}
              progress={dayProgress}
              visible={day === i && expanded}
              active={progress >= i}
              done={day > i}
            />
          </Dot>
        );
      })}
    </DotsContainer>
  );
}

export default DotsMulti;
